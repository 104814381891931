<template lang="pug">
  dropzone(
    id="postFilesDropzone"
    ref="postFilesDropzone"
    :options="options"
    @vdropzone-success="uploaded"
    @vdropzone-sending="sending"
    @vdropzone-removed-file="removed"
  )
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import videoUploaderApi from '../../api/videoUploader'

export default {
  components: {
    dropzone: vue2Dropzone
  },
  data () {
    return {
      options: {
        url: this.getUploadUrl,
        addRemoveLinks: true,
        dictDefaultMessage: 'Arraste arquivos para esta área para adicioná-los, ou clique para escolhê-los',
        timeout: 0
      },
      queue: {}
    }
  },
  async created () {
    this.videoUploadToken = await videoUploaderApi.getAccessToken()
  },
  methods: {
    getUploadUrl (files) {
      const file = files[0]
      const fileName = file.upload.uuid + '.' + file.upload.filename.split('.').slice(1).join('.') // name.extension

      if (file.type.match(/application\/*/) || file.type.match(/image\/*/)) {
        return encodeURI(`https://www.googleapis.com/upload/storage/v1/b/beyond-quoti-users-files/o?uploadType=media&name=${fileName}`)
      } else if (file.type.match(/video\/*/)) {
        return encodeURI(`https://www.googleapis.com/upload/youtube/v3/videos?part=snippet,status`)
      }
    },
    sending (file, xhr, formData) {
      const _send = xhr.send
      xhr.send = function () {
        _send.call(xhr, file)
      }

      if (file.type.match(/video\/*/)) {
        xhr.setRequestHeader('Authorization', `Bearer ${this.videoUploadToken}`)

        formData.append('snippet.categoryId', '22')
        formData.append('snippet.defaultLanguage', '')
        formData.append('snippet.description', 'Video uploaded using Dashboard v2')
        formData.append('snippet.tags[]', '')
        formData.append('snippet.title', file.upload.filename)
        formData.append('status.embeddable', '')
        formData.append('status.license', '')
        formData.append('status.privacyStatus', 'unlisted')
        formData.append('status.publicStatsViewable', '')
      }

      // One new file to upload, emit pending status for dropzone
      this.queue[file.upload.uuid] = 'pending'
      this.$emit('pending')
    },
    uploaded (file, res) {
      if (file.type.match(/application\/*/) || file.type.match(/image\/*/)) {
        const type = file.type.match(/application\/*/) ? 'file' : 'image'

        this.$emit('uploaded', {
          uuid: file.upload.uuid,
          type,
          url: res.mediaLink,
          name: file.name,
          bytes_size: file.size,
          height: file.height
        })
      } else if (file.type.match(/video\/*/)) {
        this.$emit('uploaded', {
          uuid: file.upload.uuid,
          type: 'video',
          url: `https://www.youtube.com/watch?v=${res.id}`,
          name: file.name,
          bytes_size: file.size
        })
      }

      this.queue[file.upload.uuid] = 'done'

      this.checkDone()
    },
    removed (file) {
      if (file.upload.progress === 100) {
        this.$emit('removed', {
          uuid: file.upload.uuid,
          id: file.id
        })
      }
      this.queue[file.upload.uuid] = 'done'

      this.checkDone()
    },
    checkDone () {
      // If all files are uploaded, emit done status for dropzone
      if (Object.values(this.queue).reduce((acc, cur) => {
        if (cur === 'pending') return false
        else return acc
      }, true)) {
        this.$emit('done')
      }
    },
    clearServerFiles () {
      this.$refs.postFilesDropzone.removeAllFiles()
    },
    addServerFiles (files) {
      files.forEach(file => {
        this.$refs.postFilesDropzone.manuallyAddFile({
          name: file.name,
          size: file.bytes_size,
          upload: {
            progress: 100
          },
          id: file.id
        }, file.url)
      })
    }
  }
}
</script>
