<template lang="pug">
  v-autocomplete(
    return-object
    hide-no-data
    multiple
    clearable
    item-text="name"
    :style="this.$vuetify.breakpoint.smAndDown ? 'max-width: 237px' : undefined"
    @blur="blur"
    v-model="selectedResults"
    :items="itemsSearchResult"
    :search-input.sync="itemsSearchText"
    v-on:keyup="searchUsers"
    :label="label"
    :disabled="disabled"
    :error="error"
    :loading="loading"
    :filled="filled"
    :rounded="rounded"
  )
    template(v-slot:selection="{ item, index }")
      span(
        v-if="index === 0"
        class="grey--text caption"
      ) {{ selectedResults.length }} {{ selectedResults.length === 1 ? 'seleção' : 'seleções' }}
</template>

<script>
import _ from 'lodash'

export default {
  props: [
    'listAction',
    'value',
    'label',
    'selectedUser',
    'disabled',
    'error',
    'filled',
    'rounded',
    'types'
  ],
  data () {
    return {
      loading: false,
      selectedResults: [],
      itemsSearchResult: [],
      itemsSearchText: '',
      debouncedUpdateSearch: null
    }
  },
  methods: {
    blur (event) {
      this.$emit('blur', event)
    },
    async updateSearch () {
      this.loading = true
      const listActionName = this.listAction || 'users/search'
      const results = await this.$store.dispatch(listActionName, {
        search: this.itemsSearchText,
        next: 0,
        offset: 15,
        types: this.types
      })
      this.loading = false
      this.itemsSearchResult = [...results, ...this.selectedResults]
    },
    async searchUsers () {
      this.debouncedUpdateSearch()
    }
  },
  watch: {
    selectedResults (value) {
      this.$emit('input', value)
    },
    value () {
      this.selectedResults = this.value
    }
  },
  created () {
    /**
     * Initialize debounce method
     */
    this.debouncedUpdateSearch = _.debounce(() => {
      this.updateSearch()
    }, 400)
  }
}
</script>
