<template lang="pug">
  v-card(:loading="loadings.postStatusEdit")
    v-toolbar.elevation-0(style="position: sticky;top: 0;z-index: 999;")
      v-card-title {{ flags.editing ? 'Editar' : 'Criar' }} postagem
      v-spacer
      v-btn(
        color="grey"
        icon
        large
        dense
        @click="$emit('close')"
      )
        v-icon close
    v-app-bar(
      v-if="form.type === 'post_pending' && validatePermissions(['posts.status.edit'])"
      color="warning"
      dense
      style="position: sticky;top: 0;z-index: 999;"
    )
      v-spacer
      span.white--text Pendente
      v-spacer
      v-btn(depressed @click="denyPost")
        | Rejeitar
      v-btn.ml-3(color="primary" depressed @click="allowPost")
        | Aprovar
    v-snackbar(v-model="flags.snackbar" top right) {{ snackbar.text }}
      v-btn(color="pink" text @click="flags.snackbar = false" ) Fechar
    v-form(
      ref="form"
      v-model="form.valid"
    )
      v-stepper(v-model="step" vertical).elevation-0
        v-stepper-items
          v-stepper-step(:complete="step > 1" step=1 editable v-if="!contentOnly") Grupos
          v-stepper-content(step=1 v-if="!contentOnly")
            v-container
              v-row.mb-4
                v-col(cols='auto')
                  v-select(
                    v-model="groupsRecipients.selectedUsersTypes"
                    :items="users.types"
                    item-text="name_plural"
                    return-object
                    label="Tipos de usuários"
                    filled
                    rounded
                    multiple
                    clearable
                    @blur="changedAutoComplete('recipientGroup')"
                    :style="this.$vuetify.breakpoint.smAndDown ? 'max-width: 237px' : undefined"
                    :disabled="loadings.send || flags.editing"
                    :error="errors.recipients"
                  )
                    template(v-slot:selection="{ item, index }")
                      span(
                        v-if="index === 0"
                        class="grey--text caption"
                      ) {{ groupsRecipients.selectedUsersTypes.length }} {{ groupsRecipients.selectedUsersTypes.length === 1 ? 'seleção' : 'seleções' }}
                v-col(cols='auto')
                  v-autocomplete(
                    v-model="groupsRecipients.selectedGroups"
                    :items="filtered.groups"
                    item-text="name"
                    return-object
                    label="Grupos"
                    filled
                    rounded
                    multiple
                    clearable
                    @blur="changedAutoComplete('recipientGroup')"
                    :style="this.$vuetify.breakpoint.smAndDown ? 'max-width: 237px' : undefined"
                    :disabled="loadings.send || flags.editing"
                    :error="errors.recipients"
                  )
                    template(v-slot:selection="{ item, index }")
                      span(
                        v-if="index === 0"
                        class="grey--text caption"
                      ) {{ groupsRecipients.selectedGroups.length }} {{ groupsRecipients.selectedGroups.length === 1 ? 'seleção' : 'seleções' }}
                    template(v-slot:append-outer)
                      v-menu(
                        min-width="300px"
                        :close-on-content-click="false"
                      )
                        template(v-slot:activator="{ on }")
                          v-icon(
                            v-on="on"
                            :color="form.groupsTypes.length ? 'primary' : 'grey'"
                          ) filter_list
                        v-list
                          v-list-item-group(
                            v-model="form.groupsTypes"
                            multiple
                          )
                            v-list-item(v-for="type in groups.types" :key="type.slug")
                              template(v-slot:default="{ active, toggle }")
                                v-list-item-action
                                  v-checkbox(
                                    v-model="active"
                                    color="primary"
                                    @click="toggle"
                                  )
                                v-list-item-content
                                  v-list-item-title {{ type.name }}
                //- v-col(cols='auto')
                  v-btn.ma-2.primary(fab icon small @click="addRecipientGroup" title="Adicionar usuários")
                    v-icon(color="white") add
              v-row
                v-col(cols=12)
                  v-list.my-0.py-0(no-gutters v-for="(group, index) of form.toGroups" :key="index")
                    v-divider(v-if="index > 0")
                    v-list-item
                      v-list-item-content
                        span {{group.description}}
                      v-list-item-action-text
                        v-btn(icon @click="removeRecipientGroup(group)")
                          v-icon remove_circle
                  //- v-data-table(
                    :headers="[{ text: 'Nome', value: 'name' }, { text: 'Tipo', value: 'type' }]"
                    :items="toGroup"
                    :items-per-page="5"
                    no-data-text="Nenhum destinatário selecionado"
                    )
              v-row.mt-5
                v-spacer
                v-col(cols="auto")
                  v-btn(
                    depressed
                    color="primary"
                    @click="step++"
                  )
                    span Continuar
          v-stepper-step(:complete="step > 2" step=2 editable v-if="!contentOnly") Usuários
          v-stepper-content(step=2 v-if="!contentOnly")
            v-row(v-if="loadings.usersSearch").mb-5
                v-progress-linear(indeterminate color="primary")
            v-row
              v-col(cols='auto')
                v-select(
                  v-model="usersRecipients.selectedUsersTypes"
                  :items='[{name_plural: "Responsáveis", slug: "parent"}]'
                  item-text="name_plural"
                  return-object
                  label="Tipos de usuários"
                  filled
                  rounded
                  multiple
                  clearable
                  @blur="changedAutoComplete('recipientUser')"
                  :style="this.$vuetify.breakpoint.smAndDown ? 'max-width: 237px' : undefined"
                  :disabled="loadings.send || flags.editing"
                  :error="errors.recipients"
                )
                  template(v-slot:selection="{ item, index }")
                    span(
                      v-if="index === 0"
                      class="grey--text caption"
                    ) {{ usersRecipients.selectedUsersTypes.length }} {{ usersRecipients.selectedUsersTypes.length === 1 ? 'seleção' : 'seleções' }}
              v-col(cols='auto')
                AutocompleteSearch(
                  v-model="usersRecipients.selectedUsers"
                  listAction="users/search"
                  filled
                  rounded
                  multiple
                  @blur="changedAutoComplete('recipientUser')"
                  :disabled="loadings.send  || flags.editing"
                  :error="errors.recipients"
                  label="Destinatários"
                )
                //- v-autocomplete(
                  v-model="usersRecipients.selectedUsers"
                  :items="usersRecipients.usersSearch"
                  :search-input.sync="usersRecipients.usersSearchText"
                  item-text="name"
                  return-object
                  label="Destinatários"
                  filled
                  rounded
                  multiple
                  :style="this.$vuetify.breakpoint.smAndDown ? 'max-width: 237px' : undefined"
                  hide-no-data
                  :disabled="loadings.send  || flags.editing"
                  :error="errors.recipients"
                  )
              //- v-col(cols='auto')
                v-btn.ma-2.primary(fab icon small @click="addRecipientUser" title="Adicionar usuários")
                  v-icon(color="white") add
            v-row
              v-col(cols=12)
                v-list(v-for="(user, index) of form.toUsers" :key="index")
                  v-list-item
                    v-list-item-content
                      span {{user.description}}
                    v-list-item-action-text
                      v-btn(icon @click="removeRecipientUser(user)")
                        v-icon remove_circle
            v-row.mt-5
              v-spacer
              v-col(cols="auto")
                  v-btn(
                    depressed
                    color="primary"
                    @click="step--"
                  )
                    span Voltar
              v-col(cols="auto")
                v-btn(
                  depressed
                  color="primary"
                  @click="step++"
                )
                  span Continuar
          v-stepper-step(:complete="step > 3" step=3 editable v-if="!contentOnly") Modelo e Categoria
          v-stepper-content(step=3 v-if="!contentOnly")
            v-container
              v-row
                v-col(cols="auto")
                  v-select(
                    v-model="form.model"
                    :items="posts.models"
                    item-text="name"
                    label="Modelo de postagem"
                    filled
                    rounded
                    clearable
                    :style="this.$vuetify.breakpoint.smAndDown ? 'max-width: 237px' : undefined"
                    hide-details
                    return-object
                  )
                v-col(cols="auto")
                  v-select(
                    v-model="form.category"
                    :items="posts.categories"
                    item-text="name"
                    item-value="id"
                    label="Categoria de postagem"
                    filled
                    rounded
                    clearable
                    :rules="[rules.required]"
                    :style="this.$vuetify.breakpoint.smAndDown ? 'max-width: 237px' : undefined"
                  )
                    template(v-slot:item="data")
                      v-divider
                      v-list-item
                        v-list-item-avatar(size="40")
                          v-icon(:color="data.item.color") {{data.item.icon}}
                        v-list-item-content {{data.item.name}}
              v-row.mt-5
                v-spacer
                v-col(cols="auto")
                  v-btn(
                    depressed
                    color="primary"
                    @click="step--"
                    v-if="!flags.editing"
                  )
                    span Voltar
                v-col(cols="auto")
                  v-btn(
                    depressed
                    color="primary"
                    @click="step++"
                  )
                    span Continuar
          v-stepper-step(
            :complete="contentOnly ? step > 4 : undefined"
            :step="contentOnly ? 1 : 4"
            editable
          ) Conteúdo
          v-stepper-content(:step="contentOnly ? 1 : 4")
            v-container
              v-row(v-if="loadings.send").mb-5
                v-progress-linear(indeterminate color="primary")
              v-row
                v-col(cols=12 xs=12 md=7)
                  v-row
                    v-col
                      v-text-field(
                        v-model="form.title"
                        counter="140"
                        label="Título da postagem"
                        prepend-inner-icon='mdi-format-title'
                        filled
                        rounded
                        :disabled="loadings.send"
                        :rules="[rules.required, rules.max]"
                      )
                  v-row
                    v-col(cols="12")
                      quill-editor(v-model="form.body" :options="quillEditorOptions")
                v-col(cols=12 xs=12 md=5)
                  v-row
                    v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 6")
                      v-dialog(
                        ref='dateDialog'
                        v-model='flags.date'
                        :return-value.sync='form.date'
                        width='290px'
                      )
                        template(v-slot:activator='{ on }')
                          v-text-field(
                            v-model='formmatedDate'
                            label='Data de publicação'
                            prepend-inner-icon='date_range'
                            readonly
                            filled
                            rounded
                            v-on='on'
                            :disabled="loadings.send"
                          )
                        v-date-picker(
                          v-if='flags.date'
                          v-model='form.date'
                          full-width
                          locale="pt-br"
                        )
                          v-spacer
                          v-btn(text='' color='primary' @click='flags.date = false') Cancelar
                          v-btn(text='' color='primary' @click='$refs.dateDialog.save(form.date)') Ok
                    v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 6")
                      v-dialog(
                        ref='timeDialog'
                        v-model='flags.time'
                        :return-value.sync='form.time'
                        width='290px'
                      )
                        template(v-slot:activator='{ on }')
                          v-text-field(
                            v-model='form.time'
                            label='Horário de publicação'
                            prepend-inner-icon='access_time'
                            readonly
                            filled
                            rounded
                            v-on='on'
                            :disabled="loadings.send"
                          )
                        v-time-picker(
                          v-if='flags.time'
                          v-model='form.time'
                          format="24hr"
                          full-width
                        )
                          v-spacer
                          v-btn(text='' color='primary' @click='flags.time = false') Cancelar
                          v-btn(text='' color='primary' @click='$refs.timeDialog.save(form.time)') Ok
                  v-row
                    v-col(cols=12)
                      v-text-field(
                        v-model="form.iframe"
                        label="Embbedar site da web"
                        prepend-inner-icon='link'
                        filled
                        rounded
                        :disabled="loadings.send"
                        :rules="[rules.link]"
                      )
                  v-row
                    v-col(cols=12)
                      post-files-dropzone(
                        ref="postFilesDropzone"
                        @uploaded="addFile"
                        @removed="removeFile"
                        @pending="loadings.dropzone = true"
                        @done="loadings.dropzone = false"
                      )
              v-row.mt-5
                v-spacer
                v-col(cols="auto")
                  v-btn(
                    depressed
                    color="primary"
                    @click="step--"
                  )
                    span Voltar

                v-col(cols="auto")
                  v-dialog(
                    v-model="flags.sendConfirmation"
                    width="300"
                  )
                    template(v-slot:activator="{ on }")
                      v-btn(
                        depressed
                        color="success"
                        @click="validate()"
                        :loading="loadings.send || loadings.dropzone"
                      )
                        span {{ flags.editing ? 'Editar' : 'Criar' }}
                    v-card
                      v-card-title.headline Deseja confirmar a {{ flags.editing ? 'edição' : 'criação' }} dessa postagem?
                      v-card-text(v-if="!flags.editing") Esta postagem possui {{ recipientsCount }} destinatários
                      v-card-actions
                        v-spacer
                        v-btn(
                          depressed
                          @click="flags.sendConfirmation = false"
                        ) Não
                        v-btn(
                          depressed
                          color="success"
                          @click="flags.sendConfirmation = false; create()"
                        ) Sim
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { mapState } from 'vuex'
import { quillEditor } from 'vue-quill-editor'
import PostFilesDropzone from './PostFilesDropzone'
import postsApi from '../../api/posts'
import PermissionsManager from '../../utils/permissionsManager'
import AutocompleteSearch from '../inputs/AutocompleteSearch.vue'

export default {
  props: [ 'post', 'visible', 'contentOnly' ],
  components: {
    quillEditor,
    PostFilesDropzone,
    AutocompleteSearch
  },
  data () {
    return this.getInitialData()
  },
  watch: {
    'form.date': function (date) {
      this.formmatedDate = this.$moment(date).format('DD MMM. YYYY')
    },
    'form.groupsTypes': function () {
      const form = this.form.groupsTypes.map(index => this.groups.types[index])
      if (form.length) {
        this.filtered.groups = this.groups.groups.filter(group => form.map(group => group.slug).includes(group.type))
      } else {
        this.filtered.groups = [...this.groups.groups]
      }
    },
    'form.model': function () {
      this.form.body = this.form.model.body
    },
    visible (visible) {
      if (visible) {
        this.setDefault()
      } else {
        this.clear()
      }
    },
    countRecipients () {
      this.validateRecipients()
    },
    'usersRecipients.usersSearchText': async function (text) {
      this.searchUsers(text)
    }
  },
  computed: {
    ...mapState(['users', 'groups', 'posts', 'auth'])
  },
  methods: {
    changedAutoComplete (type, event) {
      if (type === 'recipientUser') {
        if (this.usersRecipients.selectedUsersTypes && this.usersRecipients.selectedUsersTypes.length > 0 && this.usersRecipients.selectedUsers && this.usersRecipients.selectedUsers.length > 0) {
          this.addRecipientUser()
        }
      } else if (type === 'recipientGroup') { // type should be equal to recipientGroup
        if (this.groupsRecipients.selectedUsersTypes && this.groupsRecipients.selectedUsersTypes.length > 0 && this.groupsRecipients.selectedGroups && this.groupsRecipients.selectedGroups.length > 0) {
          this.addRecipientGroup()
        }
      }
    },
    async allowPost () {
      this.loadings.postStatusEdit = true

      try {
        await this.$store.dispatch('posts/allow', this.post)
        this.form.type = 'post'
      } catch (err) {
        console.error(err)

        this.snackbar.text = err.message
        this.flags.snackbar = true
      }

      this.loadings.postStatusEdit = false
    },
    async denyPost () {
      this.loadings.postStatusEdit = true

      try {
        await this.$store.dispatch('posts/deny', this.post)
        this.$emit('close')
      } catch (err) {
        console.error(err)

        this.snackbar.text = err.message
        this.flags.snackbar = true
      }

      this.loadings.postStatusEdit = false
    },
    clear () {
      Object.assign(this.$data, this.getInitialData())
      this.$refs.postFilesDropzone.clearServerFiles()
    },
    setDefault () {
      this.filtered.groups = [ ...this.groups.groups ]

      // Reset errors
      this.$refs.form.resetValidation()
      this.errors.recipients = false

      // Set form
      if (this.post) {
        this.form.title = this.post.title
        this.form.type = this.post.type
        this.form.body = this.post.body
        this.form.category = this.post.category_id
        this.form.time = this.$moment(this.post.publication_time).format('HH:mm')
        this.form.date = this.$moment(this.post.publication_time).format('YYYY-MM-DD')
        this.form.toGroups = this.post.targets && this.post.targets.groups ? this.post.targets.groups : []
        this.form.toUsers = this.post.targets && this.post.targets.users ? this.post.targets.users : []
        this.form.medias = this.post.medias.map(media => {
          return {
            ...media
          }
        })

        this.$refs.postFilesDropzone.addServerFiles(this.form.medias)

        if (!this.contentOnly) {
          this.flags.editing = true
          this.step = 4
        }
      }
    },
    getInitialData () {
      return {
        step: 1,
        formmatedDate: this.$moment().format('DD MMM. YYYY'),
        recipientsCount: 0,
        loadings: {
          send: false,
          dropzone: false,
          usersSearch: false,
          postStatusEdit: false
        },
        usersRecipients: {
          selectedUsers: [],
          selectedUsersTypes: [{
            name_plural: 'Responsáveis',
            slug: 'parent'
          }],
          usersSearch: [],
          usersSearchText: '',
          nextUsersSearchText: ''
        },
        groupsRecipients: {
          selectedGroups: [],
          selectedUsersTypes: []
        },
        form: {
          title: '',
          body: '',
          model: {},
          category: null,
          time: this.$moment().format('HH:mm'),
          date: this.$moment().format('YYYY-MM-DD'),
          medias: [],
          iframe: null,
          toGroups: [],
          toUsers: [],
          usersTypes: [],
          groupsTypes: [],
          groups: [],
          users: [],
          valid: true
        },
        filtered: {
          groups: []
        },
        flags: {
          time: false,
          date: false,
          sendConfirmation: false,
          snackbar: false,
          editing: false
        },
        errors: {
          recipients: false
        },
        rules: {
          required: v => !!v || 'O campo não pode estar vazio',
          max: v => v.length < 140 || 'O campo não pode conter mais que 140 caracteres',
          link: l => {
            const pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/ // eslint-disable-line
            return pattern.test(l) || l === null || l === '' || 'Link inválido'
          }
        },
        snackbar: {
          text: ''
        },
        quillEditorOptions: {
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'font': [] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link']
            ]
          }
        },
        validatePermissions: PermissionsManager.validatePermissions
      }
    },
    async searchUsers (query) {
      if (!this.loadings.usersSearch && query) {
        this.loadings.usersSearch = true
        this.usersRecipients.nextUsersSearchText = null
        const users = await this.$store.dispatch('users/search', {
          search: query,
          next: 0,
          offset: 15
        })
        this.loadings.usersSearch = false

        if (this.usersRecipients.nextUsersSearchText) {
          this.searchUsers(this.usersRecipients.nextUsersSearchText)
        }

        this.usersRecipients.usersSearch = [...users, ...this.usersRecipients.selectedUsers]
      } else if (this.loadings.usersSearch && query) {
        this.usersRecipients.nextUsersSearchText = query
      } else {
        this.usersRecipients.usersSearch = this.usersRecipients.selectedUsers
      }
    },
    removeRecipientUser (item) {
      this.form.toUsers = this.form.toUsers.filter(i => i !== item)
    },
    removeRecipientGroup (item) {
      this.form.toGroups = this.form.toGroups.filter(i => i !== item)
    },
    addRecipientUser () {
      if (this.usersRecipients.selectedUsers.length === 0) {
        this.$store.dispatch('app/showSnackbar', 'Selecione ao menos um usuário')
      } else {
        for (const user of this.usersRecipients.selectedUsers) {
          const typeAssocs = this.usersRecipients.selectedUsersTypes.map(userType => userType.slug)
          const typeAssocsNames = this.usersRecipients.selectedUsersTypes.map(userType => userType.name_plural)

          let description = `Ao usuário ${user.name.split(' ').slice(0, 2).join(' ')}`

          if (typeAssocsNames.length > 0) {
            description += ` e seus ${typeAssocsNames}`
          }

          const toUser = {
            type: 'user',
            description: description,
            bonds: ['self', ...typeAssocs],
            user_id: user.id
          }

          this.form.toUsers.push(toUser)
        }

        this.usersRecipients.selectedUsers = []
        this.usersRecipients.selectedUsersTypes = []
      }
    },
    addRecipientGroup () {
      // Se não foi selecionado nenhum grupo, marca todas as filiais...
      if (this.groupsRecipients.selectedUsersTypes.length === 0) {
        this.$store.dispatch('app/showSnackbar', 'Selecione ao menos um tipo de usuário')
      } else {
        if (this.groupsRecipients.selectedGroups.length === 0) {
          for (const filial of this.groups.groupsChildren.filiais) {
            this.groupsRecipients.selectedGroups.push(filial)
          }
        }

        for (const group of this.groupsRecipients.selectedGroups) {
          const typeAssocs = this.groupsRecipients.selectedUsersTypes.map(userType => userType.slug)
          const typeAssocsNames = this.groupsRecipients.selectedUsersTypes.map(userType => userType.name_plural)

          const last = typeAssocsNames.pop()
          let description = typeAssocsNames.join(', ')
          description += description.length ? ` e ${last}` : last

          const toGroup = {
            description: `${description} do(a) ${group.name} `,
            type: 'group',
            group_cod: group.cod,
            bonds: typeAssocs
          }

          this.form.toGroups.push(toGroup)
        }

        this.groupsRecipients.selectedGroups = []
        this.groupsRecipients.selectedUsersTypes = []
      }
    },
    async validateRecipients () {
      this.recipientsCount = await postsApi.countUsersFromTargets({
        groups: this.form.toGroups,
        users: this.form.toUsers
      })

      this.errors.recipients = this.recipientsCount === 0

      return this.recipientsCount > 0
    },
    async validate () {
      this.loadings.send = true

      try {
        if (!this.$refs.form.validate()) {
          throw new Error('Preencha todos os campos corretamentes')
        }

        if (!this.post) {
          const recipientsValidation = await this.validateRecipients()
          if (!recipientsValidation) {
            throw new Error('O post não possui destinatários')
          }
        }

        this.flags.sendConfirmation = true
      } catch (err) {
        this.snackbar.text = err.massage
        this.flags.snackbar = true
      }

      this.loadings.send = false
    },
    addFile (data) {
      this.form.medias.push({
        ...data
      })
    },
    removeFile (data) {
      if (data.id) {
        this.form.medias = this.form.medias.filter(m => m.id !== data.id)
      } else {
        this.form.medias = this.form.medias.filter(m => m.uuid !== data.uuid)
      }
    },
    async create () {
      this.loadings.send = true

      if (this.form.iframe) {
        this.form.medias.push({
          type: 'iframe',
          url: this.form.iframe
        })
      }

      try {
        if (!this.flags.editing) {
          const to = [...this.form.toGroups, ...this.form.toUsers]

          to.map(t => {
            delete t.description
          })

          await this.$store.dispatch('posts/create', {
            title: this.form.title,
            body: this.form.body || '',
            category_id: this.form.category,
            publication_time: this.$moment(`${this.form.date} ${this.form.time}`).format('YYYY-MM-DD HH:mm:ss'),
            medias: this.form.medias,
            to
          })

          this.$emit('created')
        } else {
          await this.$store.dispatch('posts/edit', {
            id: this.post.id,
            title: this.form.title,
            body: this.form.body || '',
            category_id: this.form.category,
            publication_time: this.$moment(`${this.form.date} ${this.form.time}`).format('YYYY-MM-DD HH:mm:ss'),
            medias: this.form.medias
          })

          this.$emit('edited')
        }
      } catch (err) {
        console.error(this.flags.editing ? 'ERROR_EDIT_POST' : 'ERROR_CREATE_POST', err)
        this.loadings.send = false
        this.snackbar.text = `Erro ao ${this.flags.editing ? 'editar' : 'criar'} a postagem. ${err}`
        this.flags.snackbar = true

        // If error, remove added iframe link
        this.form.medias = this.form.medias.filter(m => m.type !== 'iframe')
      }

      this.loadings.send = false
    }
  },
  mounted () {
    this.setDefault()
  }
}
</script>
