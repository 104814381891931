<template lang="pug">
  v-card(:loading="loading" color="black")
    v-toolbar(
      flat
    ).transparent
      v-spacer
      v-btn(
        color="grey"
        icon
        large
        dense
        @click="$emit('close')"
      )
        v-icon close
    v-snackbar(v-model="snackbar.enabled" top right) {{ snackbar.text }}
      v-btn(color="pink" text @click="snackbar.enabled = false" ) Fechar
    cropper(
      v-if="image"
      ref="cropper"
      :src="image"
      :stencilComponent="$options.components.CircleStencil"
      :stencil-props="stencilProps"
      :style="$vuetify.breakpoint.smAndUp ? { height: height } : null"
    )
    v-card-text(v-else)
      v-row.justify-center.align-center
        v-progress-circular.mt-5(
          :size="50"
          color="primary"
          indeterminate
        )
    v-card-actions
      v-spacer
      v-btn(
        color="grey"
        text
        dense
        :loading="loading"
        @click="save"
      )
        | Cortar
</template>

<script>
import { Cropper, CircleStencil } from 'vue-advanced-cropper'
import firebase from '../../configs/firebase'

export default {
  name: 'PhotoEditor',
  props: ['user', 'image', 'imageName'],
  components: {
    Cropper,
    CircleStencil
  },
  data: function () {
    return {
      loading: false,
      snackbar: {
        enabled: false,
        text: null
      },
      stencilProps: {
        aspectRatio: 1
      },
      height: null
    }
  },
  mounted () {
    this.height = `${window.innerHeight - 206}px`
  },
  methods: {
    async save () {
      const { canvas } = this.$refs.cropper.getResult()
      const image = canvas.toDataURL()

      this.loading = true

      try {
        // Save image to Firebase
        const storageRef = await firebase.storage().ref()
        const userProfileImageRef = storageRef.child(`users-profiles-images/${this.imageName}`)
        const snapshot = await userProfileImageRef.putString(image, 'data_url')
        const url = await snapshot.ref.getDownloadURL()

        // Emit save event with url
        this.$emit('save', url)

        this.$emit('close')
      } catch (err) {
        this.snackbar.text = err.message
        this.snackbar.enabled = true
      }

      this.loading = false
    }
  }
}
</script>
