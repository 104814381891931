<style lang="sass" scoped>
.sent
  border-radius: 12px 4px 12px 12px
  text-align: right

.received
  border-radius: 12px 12px 12px 4px
  text-align: left
</style>

<template lang="pug">
  v-row(:justify="message.type === 'sent' ? 'end' : 'start'")
    v-col.d-flex(cols='auto')
      v-card.clickable(:class='message.type' outlined :color="message.type === 'sent' ? 'primary lighten-2' : 'secondary lighten-3' ")
        v-container.py-0(@click="openMessageRead()")
          v-row.pt-4(no-gutters)
            v-col(:class="message.type === 'sent' ? 'text-end' : 'text-start'")
              v-img.mb-2(
                v-if="message.meta && message.meta.type === 'image'"
                :src='message.meta.link'
                contain lazy-src="static/quoti_logo_bg_blug_round_big.png"
                min-height="50"
                min-width="50"
                max-height="200"
                max-width="200"
                @click.stop='flags.imageViewer = true'
              )
                template(v-slot:placeholder)
                  v-container.pa-0(fill-height justify-center align-center)
                    v-progress-circular(indeterminate)
              span.body-1(
                v-if="message.meta && message.meta.type === 'pdf'"
                @click.stop="open(message.meta.link)"
              )
                a {{ message.text }}
              span.body-1(v-if="(!message.meta || Object.keys(message.meta).length === 0 || message.meta.type === 'text') && message.text") {{ message.text }}
          v-row(no-gutters)
            v-col(align-self='end')
              span.caption(v-if="message.created_at") {{ message.created_at | timeAgo }}
    v-dialog(v-model="flags.reads" scrollable max-width="360")
      v-card
        v-toolbar(flat)
          span Leituras
          v-spacer
          v-btn(icon @click="flags.reads = false")
            v-icon close
        v-card-text(v-if="flags.loadingReads")
          v-progress-circular(indeterminate size="64")
        v-card-text(v-else)
          v-list(v-if="reads && reads.length")
            v-list-item(v-for="read in reads" :key="read.id")
              v-list-item-avatar(v-if="read.user_image_url && read.user_image_url.length > 0")
                v-img(:src="read.user_image_url" width="25px")
              v-list-item-avatar(v-else)
                v-icon(size=40) account_circle
              v-list-item-content
                v-list-item-title {{read.user_name}}
                v-list-item-subtitle {{read.read_at | timeAgo }}
          span(v-else) Mensagem não lida
    v-dialog(
      v-model="flags.imageViewer"
      :width="width"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    )
      v-card(color="black")
        v-toolbar(
          absolute
          flat
        ).transparent
          v-spacer
          v-btn(
            color="grey"
            icon
            large
            dense
            @click="flags.imageViewer = false"
          )
            v-icon close
        v-zoomer
          v-img(
            v-if="message.meta"
            :src="message.meta.link"
            :height="height"
            contain
          )
</template>

<script>
import Firebase from '../../store/firebase'
import moment from 'moment-timezone'
import { mapState } from 'vuex'
import Permissions from '../../utils/permissionsManager'

const firestore = Firebase.firestore

export default {
  name: 'message',
  props: {
    message: {
      type: Object
    }
  },
  data () {
    return {
      reads: [],
      flags: {
        reads: false,
        loadingReads: false,
        imageViewer: false
      },
      height: null,
      width: null
    }
  },
  created () {
    this.height = this.$vuetify.breakpoint.smAndDown ? window.innerHeight + 'px' : `${window.innerHeight - 100}px`
    this.width = `${window.innerWidth - 100}px`
  },
  computed: {
    ...mapState(['auth'])
  },
  filters: {
    timeAgo (date) {
      return moment(date.toDate()).fromNow()
    }
  },
  methods: {
    open (link) {
      window.open(link)
    },
    // showImage (message) {
    //   this.$store.dispatch(`chat/showImage`, message)
    // },
    async openMessageRead () {
      if (!this.validatePermissions(['chat.viewReadStatus'])) {
        return
      }

      this.flags.loadingReads = true
      const messagePath = this.message['.uid'].replace('1///', '')
      const reads = await firestore.doc(messagePath).collection('reads').get()
      this.reads = []
      reads.forEach((read) => {
        this.reads.push({
          id: read.id,
          ...read.data()
        })
      })
      this.flags.reads = true
      this.flags.loadingReads = false
    },
    validatePermissions: Permissions.validatePermissions
  }
}
</script>
