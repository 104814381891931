<template lang="pug">
  dropzone(
    id="dynamicComponentsFilesDropzone"
    ref="dropzone"
    :options="options"
    @vdropzone-success="uploaded"
    @vdropzone-sending="sending"
    @vdropzone-removed-file="removed"
  )
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import md5 from 'md5'

export default {
  components: {
    dropzone: vue2Dropzone
  },
  data () {
    return {
      options: {
        url: this.getUploadUrl,
        addRemoveLinks: true,
        dictDefaultMessage: 'Arraste o arquivo .vue para esta área para adicioná-lo, ou clique para escolhê-lo',
        maxFiles: 1,
        acceptedFiles: '.vue'
      },
      file: {
        name: ''
      }
    }
  },
  methods: {
    getUploadUrl (files) {
      const institution = this.$route.params.institution || process.env.VUE_APP_CORDOVA
      this.file.name = `${institution}/${md5(new Date())}.vue`
      return encodeURI(`https://www.googleapis.com/upload/storage/v1/b/dynamic-components/o?uploadType=media&name=${this.file.name}`)
    },
    sending (file, xhr, formData) {
      const _send = xhr.send
      xhr.send = function () {
        _send.call(xhr, file)
      }
      this.$emit('pending')
    },
    uploaded (file, res) {
      const institution = this.$route.params.institution || process.env.VUE_APP_CORDOVA
      const fileName = this.file.name.replace(`${institution}/`, '')
      console.log('UUUPLOADED', fileName)
      this.$emit('uploaded', {
        name: fileName,
        bytes_size: file.size,
        // url: res.mediaLink
        url: `https://storage.cloud.google.com/dynamic-components/${fileName}`
      })
    },
    clearServerFiles () {
      this.$refs.dropzone.removeAllFiles()
    },
    removed (file) {
      if (file.upload.progress === 100) {
        this.$emit('removed')
      }
    }
  }
}
</script>
