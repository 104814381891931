import * as firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/storage'
import 'firebase/firestore'

export default firebase.initializeApp({
  apiKey: 'AIzaSyDGspCCXnvvrD1hTJdMjFdX5GgmKI1OOKQ',
  authDomain: 'agenda-conectada.firebaseapp.com',
  projectId: 'agenda-conectada',
  storageBucket: 'agenda-conectada.appspot.com',
  messagingSenderId: '321568992939',
  appId: '1:321568992939:web:5ef278be5a9f85566f91ee',
  measurementId: 'G-S0QYP9W1FS'
})
