<template lang="pug">
  v-card.mt-3.pt-0(ref="post" :post-id='post.id' :style="`border-left: ${post.category_color} solid 3px`" :class="post.type === 'post_pending' ? 'grey lighten-4 elevation-0' : ''")
    v-dialog(
      v-model="flags.focus"
      :width="focus.width"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    )
      v-card(color="black")
        v-toolbar(
          absolute
          flat
        ).transparent
          v-spacer
          v-btn(
            color="grey"
            icon
            large
            dense
            @click="flags.focus = false"
          )
            v-icon close
        v-zoomer
          v-img(
            v-if="focus.window"
            :src="focus.window.url"
            :height="focus.height"
            contain
          )
    //--
      kbd.mt-0.pt-0.info
        span {{post.id}}

    v-card-title.pt-2.pb-1
      //- :class="{'pr-4': $vuetify.breakpoint.mdAndDown}"
      v-row(align='center' justify='start' no-gutters)
        v-col.mr-2(cols="auto")
          v-avatar(size="40px")
            v-tooltip(bottom)
              template(v-slot:activator="{ on }")
                img(v-on="on" :src="creatorUserImage")
              span {{post.creator_user_name}}
        v-col
          v-layout(column wrap align='start' justify='start')
            span.clickable.title.font-weight-light(@click="navigateToPost(post)") {{post.title}}
            //- span.caption.font-weight-light(:title="post.resume_recipient") {{limitRecipients(post.resume_recipient)}}
            span.caption.font-weight-light(:title="post.assocs_resume_title") {{post.assocs_resume}}
        v-col(cols='auto' align='end' v-if='!preview')
          v-menu(
            bottom
            left
            v-if="validateSomePermission(['posts.status.edit', 'posts.edit', 'posts.delete', 'posts.reply'])"
          )
            template(v-slot:activator="{ on }")
              v-btn(icon v-on="on" :loading='loadingActionOnPost')
                v-icon more_vert
            v-list
              v-list-item(
                :disabled="post.type !== 'post_pending'"
                @click="allowPost()"
                v-if="validatePermissions(['posts.status.edit'])"
              )
                v-list-item-icon
                  v-icon done
                v-list-item-content
                  v-list-item-title
                    span &nbsp; Aprovar postagem
              v-list-item(
                :disabled="post.type !== 'post_pending'"
                @click="denyPost()"
                v-if="validatePermissions(['posts.status.edit'])"
              )
                v-list-item-icon
                  v-icon close
                v-list-item-content.text-xs-left
                  v-list-item-title
                    span &nbsp; Rejeitar postagem
              v-list-item(
                @click="editPost()"
                v-if="validatePermissions(['posts.edit'])"
              )
                v-list-item-icon
                  v-icon edit
                v-list-item-content
                  v-list-item-title
                    span &nbsp; Editar postagem
              v-list-item(
                @click="deletePost()"
                v-if="validatePermissions(['posts.delete'])"
              )
                v-list-item-icon
                  v-icon delete
                v-list-item-content
                  v-list-item-title
                    span &nbsp; Apagar postagem
              v-list-item(
                @click="replyPost()"
              )
                v-list-item-icon
                  v-icon reply
                v-list-item-content
                  v-list-item-title Responder postagem
    div.ma-2(class="text-left")
      v-chip(
        small
        :color="post.category_color"
        text-color="white"
      ) {{post.category_name}}
    v-card-text(v-if="convertedHtml.length > 0").pa-0.pr-4.ma-3
      div(v-html="convertedHtml")
    v-carousel(
      v-if="windows.length"
      v-model="states.window"
      :hide-delimiters="windows.length === 1"
      :show-arrows="windows.length > 1"
    )
      v-carousel-item(
        v-for="window in windows"
        :key="`carouselWindow-${window.id}`"
        :src="window.type === 'image' ? window.url : undefined"
        @click="activeWindowFocus(window)"
      )
        //- v-img.grey.lighten-4(
        //-   v-if="window.type === 'image'"
        //-   :height="window.height ? window.height + 'px' : undefined"
        //-   :src="window.url"
        //-   max-height="34em"
        //-   class="white--text"
        //-   contain
        //- )
        div(v-if="window.type === 'iframe'")
          iframe(
            :src="window.url"
            height="500"
            width="100%"
          )
        v-card(v-if="window.type === 'video'")
          youtube(class="youtube" :video-id="getYoutubeVideoId(window.url)")
    div
      v-chip(
        v-for="file in files"
        :key="file.id"
        @click="openFile(file.url)"
        small
      ).ma-2
        v-avatar(left)
          v-icon(small) attach_file
        | {{ file.name ? reducedFileName(file.name) : 'Visualizar arquivo' }}
    v-divider
    v-card-text.pt-3(v-if="validatePermissions(['posts.views'])")
      v-container.pa-0
        v-row.pl-3(align='start' justify='start' v-if="!preview")
          v-spacer(v-if="$vuetify.breakpoint.smAndUp")
          div.ma-0.pa-0.xs-5(v-if="post.type !== 'post_pending'")
            v-menu(open-on-hover bottom offset-y)
              template( v-slot:activator="{ on }" )
                v-chip.white--text.mr-1(small label color='blue' v-on="on" @mouseover="listRecipients('read', post)")
                  div(v-if="$vuetify.breakpoint.xsOnly")
                    v-avatar.clickable {{post.read_count}}
                    v-icon mdi-eye-outline
                  div(v-else)
                    v-avatar.clickable {{post.read_count}}
                    span.clickable Visualizações
              v-list.users-list
                v-list-item.ma-0.py-0(v-for="(item, i) in read" :key="i")
                  v-list-item-icon.mr-2(v-if="item.id !== -1")
                    v-img(:src="item.url" width="25px")
                  v-list-item-content
                    v-list-item-title {{ item.name }}
          div.ma-0.pa-0(v-if="post.type !== 'post_pending'")
            v-menu(open-on-hover bottom left offset-y)
              template( v-slot:activator="{ on }" )
                v-chip.white--text.mr-1(small label v-on="on" @mouseover="listRecipients('queued', post)" color="error")
                  div(v-if="$vuetify.breakpoint.xsOnly")
                    v-avatar.clickable(color="error") {{post.recipient_count - post.read_count}}
                    v-icon mdi-eye-off-outline
                  div(v-else)
                    v-avatar.clickable(color="error") {{post.recipient_count - post.read_count}}
                    span.clickable Não visualizados
              v-list.users-list
                v-list-item.ma-0.py-0(v-for="(item, i) in queued" :key="i")
                  v-list-item-icon.mr-2(v-if="item.id !== -1")
                    v-img(:src="item.url" width="25px")
                  v-list-item-content
                    v-list-item-title {{ item.name }}
          div.ma-0.pa-0
            v-menu(open-on-hover bottom left offset-y)
              template( v-slot:activator="{ on }" )
                v-chip.grey.white--text(small label v-on="on" @mouseover="listRecipients('any', post)")
                  v-avatar.clickable.grey {{post.recipient_count}}
                  span.clickable Destinatários
              v-list.users-list
                v-list-item.ma-0.py-0(v-for="(item, i) in any" :key="i")
                  v-list-item-icon.mr-2(v-if="item.id !== -1")
                    v-img(:src="item.url" width="25px")
                  v-list-item-content
                    v-list-item-title {{ item.name }}
    v-card-actions.mt-0(v-if="validatePermissions(['posts.views'])")
      span.body-2.pt-0.pl-2.grey--text Postagem criada  {{parsedCreatedTime}} por&nbsp;
        a(@click="goUser(post)") {{post.creator_user_name}}
        span.grey--text  com publicação em {{parsedPublicationTime}}
    v-card-actions.mt-0(v-else)
      span.body-2.pt-0.pl-2.grey--text Postagem publicada em {{parsedPublicationTime}}
</template>

<script>

import ApiPosts from '../../api/posts'
import Permissions from '../../utils/permissionsManager'
import { mapState } from 'vuex'

export default {
  name: 'post',
  data () {
    return {
      loadingActionOnPost: false,
      read: [{ id: -1, name: 'Listando usuários' }],
      queued: [{ id: -1, name: 'Listando usuários' }],
      any: [{ id: -1, name: 'Listando usuários' }],
      states: {
        window: 0
      },
      flags: {
        focus: false
      },
      focus: {
        window: null,
        width: '800px',
        height: '600px'
      }
    }
  },
  props: [
    'refreshWatch',
    'preview',
    'post',
    'intersectionObserver'
  ],
  // props: {
  //   refreshWatch: {
  //     type: Number
  //   },
  //   preview: {
  //     type: Boolean,
  //     default: false
  //   },
  //   post: {
  //     type: Object,
  //     default: () => {
  //       return {
  //         title: '',
  //         body: '',
  //         time: this.$moment().format('hh:mm'),
  //         date: this.$moment().format('YYYY-MM-DD'),
  //         mediaURL: [],
  //         medias: [],
  //         images: [],
  //         category_id: '',
  //         to: [],
  //         resume_recipient: ''
  //       }
  //     }
  //   },
  //   intersectionObserver: {}
  // },
  computed: {
    ...mapState(['user', 'organization', 'auth']),
    creatorUserImage () {
      return this.post.creator_user_image ? this.post.creator_user_image : this.organization.infos.image.logo
    },
    parsedCreatedTime () {
      let parsed = this.$moment(this.post.created_time).calendar()
      return `${parsed.charAt(0).toLowerCase()}${parsed.slice(1)}`
    },
    parsedPublicationTime () {
      let parsed = this.$moment(this.post.publication_time).calendar()
      return `${parsed.charAt(0).toLowerCase()}${parsed.slice(1)}`
    },
    convertedHtml () {
      /* eslint-disable */
      // old quoti regex: var regex = /(\'|\")?(https?\:\/\/)?[a-z0-9\-]+(\.[a-z0-9\-]+)+((\/|\?)[a-zA-Z0-9\?\&\=\?\.\-\_\/]+|\/)?/g 
      if (this.post.body && typeof this.post.body === typeof '') {
        var regex = /(\'|\")?(https?\:\/\/)?[a-z0-9\-]+(\.[a-z0-9\-]+)*(\.[a-z\-]+)((\/|\?)[a-zA-Z0-9\%\&\=\?\.\-\_\/]+|\/)?/g      
        return (this.post.body.replace(regex, function (match) {
          if (match.match(/^(\.|\d)*$/g)) {
            return match
          }
          if (match.startsWith("'") || match.startsWith('"')) {
            return match
          }
          var url = (match.startsWith('http://') || match.startsWith('https://')) ? match : 'http://' + match
          return `<a style="word-wrap: break-word;" target="_blank" href="${url}">${match}</a>`
        })).trim()
      } else {
        return ''
      }
      /* eslint-enable */
    },
    windows () {
      return this.post.medias ? this.post.medias.filter(media => {
        switch (media.type) {
          case 'image':
            return true
          case 'video':
            return true
          case 'iframe':
            return true
          default:
            return false
        }
      }) : []
    },
    files () {
      return this.post.medias ? this.post.medias.filter(m => m.type !== 'iframe') : []
    },
    postUrl () {
      return `https://quoti.com.br/${this.$route.params.institution}/post/${this.post.id}`
    }
  },
  watch: {
    refreshWatch () {
      this.$forceUpdate()
    },
    post () {
    },
    'post.resume_recipient' () {
    }
  },
  methods: {
    reducedFileName (name) {
      if (name.length < 11) {
        return name
      } else {
        return name.slice(0, 10) + '...'
      }
    },
    navigateToPost (post) {
      this.$router.push({
        name: 'PostPage',
        params: {
          postId: post.id
        }
      })
    },
    activeWindowFocus (window) {
      if (window.type === 'image') {
        this.focus.window = window
        this.flags.focus = true
      }
    },
    getYoutubeVideoId (url) {
      const urlObj = new URL(url)
      const search = new URLSearchParams(urlObj.search)
      return search.get('v')
    },
    openFile (fileUrl) {
      window.open(fileUrl, '_system')
    },
    async allowPost () {
      this.$emit('confirmAction', {
        title: 'Aprovar publicação',
        body: 'Tem certeza que deseja aprovar essa postagem?',
        snackbarText: 'Postagem aprovada!',
        func: async () => {
          this.loadingActionOnPost = true

          try {
            await this.$store.dispatch('posts/allow', this.post)
            this.post.type = 'post'
          } catch (err) {
            console.error(err)
          }

          this.loadingActionOnPost = false
        }
      })
    },
    async denyPost () {
      this.$emit('confirmAction', {
        title: 'Rejeitar publicação',
        body: 'Tem certeza que deseja rejeitar essa postagem?',
        snackbarText: 'Postagem rejeitada!',
        func: async () => {
          this.loadingActionOnPost = true

          try {
            await this.$store.dispatch('posts/deny', this.post)
            this.post.type = 'post'
          } catch (err) {
            console.error(err)
          }

          self.loadingActionOnPost = false
        }
      })
    },
    async deletePost () {
      const self = this
      this.$emit('confirmAction', {
        title: 'Apagar publicação',
        body: 'Tem certeza que deseja apagar essa postagem?',
        snackbarText: 'Postagem deletada!',
        func: async function () {
          self.loadingActionOnPost = true
          await self.$store.dispatch('posts/delete', self.post)
          self.loadingActionOnPost = false
        }
      })
    },
    async editPost () {
      // this.$store.dispatch('post/postForEdit', this.post)
      this.$emit('edit', this.post)
    },
    goUser (post) {
      this.$router.push({
        name: 'UserProfile',
        params: {
          userId: post.creator_user_id
        }
      })
    },
    async listRecipients (status, post) {
      this[status] = (await ApiPosts.recipients(status, post.id)).map((recipient) => {
        recipient.url = recipient.url ? recipient.url : 'https://s3.amazonaws.com/quoti/image-uploads/713e800ee36b95ccb33ddd2a85160a8b_thumb.jpg'
        return recipient
      })
    },
    replyPost () {
      this.$emit('reply', this.post)
    },
    validatePermissions: Permissions.validatePermissions,
    validateSomePermission: Permissions.validateSomePermission
  },
  mounted () {
    this.intersectionObserver.observe(this.$refs.post.$el)

    this.focus.height = this.$vuetify.breakpoint.smAndDown ? window.innerHeight + 'px' : `${window.innerHeight - 100}px`
    this.focus.width = `${window.innerWidth - 100}px`
  }
}
</script>

<style lang="stylus">

  .vchip-infomartion
    font-size: 11px !important
    height: 20px !important
  .users-list
    max-height: 250px !important
    background-color: #ffffff !important
  .users-list > *
    background-color: #ffffff !important
</style>

<style lang="stylus">
.youtube iframe {
  width: 100%;
  height: 500px;
}
</style>
