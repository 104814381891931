<template lang="pug">
  v-card(:loading="loadings.openInNew")
    v-dialog(
      v-model="flags.photoEditor"
    )
      PhotoEditor(
        ref="photoEditor"
        @close="closePhotoEditor"
        @save="saveNewProfileImage"
        :image="newProfileImage"
        :imageName="newProfileImageFileName"
      )
    v-snackbar(v-model="flags.snackbar" top right) {{ snackbar.text }}
      v-btn(color="pink" text @click="flags.snackbar = false" ) Fechar
    v-toolbar(extended).elevation-0
      v-toolbar-title {{ flags.editing ? 'Editar' : 'Criar' }} usuário
      v-spacer
      v-btn(
        v-if="this.flags.editing"
        text
        color="grey"
        dense
        @click="logAsUser"
      )
        v-icon(left) open_in_new
        | Acessar como
      v-btn(
        color="grey"
        icon
        large
        dense
        @click="$emit('close')"
      )
        v-icon close
    v-card-text
      v-row.justify-center.text-align-center.text-center
        v-badge(
          icon="mdi-pencil"
          bottom
          right
          overlap
          bordered
          offset-x="25"
          offset-y="25"
        )
          v-avatar(size="100" @click="openPhotoEditor")
            v-img(v-if="form.photo" :src="form.photo")
            v-icon(
              v-else
              size="100"
              color="primary"
            ) mdi-account-circle
      v-row.justify-center.text-align-center.text-center
        span.headline {{ form.name || 'Nome do usuário' }}
      v-row.justify-center.text-align-center.text-center
        span.subtitle-1 {{ form.type && form.type.name ? form.type.name : 'Tipo do usuário' }}
      v-row(v-if="lastLoginTime").justify-center.text-align-center.text-center
        span.caption {{ lastSeen }}
      v-row(v-if="flags.editing").justify-center.text-align-center.text-center
        span.caption {{ createdAt }}
    v-tabs(
      color="primary"
      grow
    ).mt-4
      v-tab Dados
      v-tab Grupos
      v-tab Relacionamentos
      v-tab-item.mt-4
        v-card(flat)
          v-card-text
            v-form(ref="form")
              v-row
                v-col(cols=12)
                  v-text-field(
                    v-model="form.name"
                    label="Nome do usuário"
                    hint="Nome do usuário"
                    filled
                    rounded
                    :disabled="loadings.save"
                    :rules="[rules.required(form.name)]"
                  )
                v-col(cols=12)
                  v-text-field(
                    v-model="form.user"
                    filled
                    rounded
                    label="Login do usuário"
                    :disabled="loadings.save"
                    :rules="[rules.required(form.user)]"
                  )
                v-col(cols=12)
                  v-select(
                    v-model="form.type"
                    :items="users.types"
                    item-text="name"
                    label="Tipo do usuário"
                    return-object
                    filled
                    rounded
                    :disabled="loadings.save"
                    :rules="[rules.required(form.type)]"
                  )
                v-col(cols=12)
                  v-select(
                    v-model="form.profile"
                    :items="users.profiles"
                    item-text="name"
                    label="Perfil do usuário"
                    return-object
                    filled
                    rounded
                    :disabled="loadings.save"
                    :rules="[rules.required(form.type)]"
                  )
                v-col(cols=12)
                  v-text-field(
                    v-model="form.matricula"
                    filled
                    rounded
                    label="Matrícula do usuário"
                    :disabled="loadings.save"
                  )
                v-col(cols=12)
                  v-text-field(
                    v-model="form.cpf"
                    filled
                    rounded
                    label="CPF do usuário"
                    :disabled="loadings.save"
                  )
                v-col(cols=12)
                  v-text-field(
                    v-model="form.email"
                    filled
                    rounded
                    label="Email do usuário"
                    :disabled="loadings.save"
                  )
                v-col(cols=12)
                  v-text-field(
                    v-model="form.telefone"
                    filled
                    rounded
                    label="Telefone do usuário"
                    :disabled="loadings.save"
                  )
                v-col(cols=6)
                  v-switch(
                    v-model="form.registered"
                    label="Usuário registrado"
                    :disabled="loadings.save"
                    inset
                  )
                v-col(cols=6)
                  v-switch(
                    v-model="form.sync"
                    label="Sincronização ativa"
                    :disabled="loadings.save"
                    inset
                  )
      v-tab-item.mt-4
        v-card(flat)
          v-card-text
            v-row
              v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 5")
                v-autocomplete(
                  v-model="groupsAssocs.selected.groups"
                  :items="groups.groups"
                  item-text="name"
                  return-object
                  label="Grupos"
                  persistent-hint
                  filled
                  rounded
                  multiple
                  hide-no-data
                  hide-details
                  :disabled="loadings.send"
                )
                  template(v-slot:selection="{ item, index }")
                    span(
                      v-if="index === 0"
                      class="grey--text caption"
                    ) {{ groupsAssocs.selected.groups.length }} {{ groupsAssocs.selected.groups.length === 1 ? 'seleção' : 'seleções' }}
              v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 5")
                v-select(
                  v-model="groupsAssocs.selected.role"
                  item-text="name"
                  return-object
                  :items="groupsAssocs.roles"
                  label="Papel nos grupos"
                  filled
                  rounded
                  :disabled="loadings.send"
                  hide-details
                )
              v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 2")
                v-btn.ma-2.primary(
                  fab
                  icon
                  small
                  @click="addSelectedGroups()"
                  :disabled="loadings.send"
                  title="Adicionar grupo"
                )
                  v-icon(color="white") add
            v-row
              v-col(cols=12)
                span.subtitle-2 Associações com grupos
              v-col(cols=12).mb-0.pb-0
                v-text-field(
                  v-model="groupsAssocs.search.text"
                  label="Pesquisar associações"
                  prepend-inner-icon="search"
                  rounded
                )
              v-col(cols=12 v-if="groupsAssocs.search.result.length > 0").mb-5.mt-0.pt-0
                v-list(max-height="300px")
                  v-list-item(v-for="(assoc, index) of groupsAssocs.search.result" :key="`assoc-${index}`")
                    v-list-item-avatar
                      v-icon people
                    v-list-item-content
                      v-row
                        v-col(cols=6) {{assoc.group_name}}
                        v-col(cols=6) {{assoc.role_name}}
                    v-list-item-action-text
                      v-btn(
                        icon
                        @click="removeAssoc(assoc)"
                      )
                        v-icon remove_circle
              v-col(cols=12 v-if="loadings.assocs")
                v-row(justify="center" align="center")
                  v-progress-circular(indeterminate color="primary")
              v-col(cols=12 v-if="groupsAssocs.assocs.length === 0 && !loadings.assocs")
                span.subtitle-3 O usuário não está associado à um grupo
            v-row(v-if="groupsAssocs.added.length > 0")
              v-col(cols=12).pb-1
                span.subtitle-2 Novas associações
              v-col(cols=12)
                perfect-scrollbar
                  v-list(max-height="300px")
                    v-list-item(v-for="(assoc, index) of groupsAssocs.added" :key="`newAssoc-${index}`")
                      v-list-item-avatar
                        v-icon group
                      v-list-item-content
                        v-row
                          v-col(cols=6) {{assoc.group_name}}
                          v-col(cols=6) {{assoc.role_name}}
                      v-list-item-action-text
                        v-btn(
                          icon
                          @click="removeAddedAssoc(assoc)"
                        )
                          v-icon remove_circle
      v-tab-item.mt-4
        v-card(flat)
          v-card-text
            v-row
              v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 5")
                v-autocomplete(
                  v-model="connections.selected.users"
                  :items="connections.search.users.result"
                  :search-input.sync="connections.search.users.text"
                  item-text="name"
                  return-object
                  label="Usuários"
                  persistent-hint
                  filled
                  rounded
                  multiple
                  hide-no-data
                  hide-details
                  :loading="loadings.connections.users"
                  :disabled="loadings.send"
                )
                  template(v-slot:selection="{ item, index }")
                    span(
                      v-if="index === 0"
                      class="grey--text caption"
                    ) {{ connections.selected.users.length }} {{ connections.selected.users.length === 1 ? 'seleção' : 'seleções' }}
              v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 5")
                v-select(
                  v-model="connections.selected.type"
                  item-text="name"
                  return-object
                  :items="connections.types"
                  label="Conectar como"
                  filled
                  rounded
                  :disabled="loadings.send"
                  hide-details
                )
              v-col(:cols="$vuetify.breakpoint.smAndDown ? 12 : 2")
                v-btn.ma-2.primary(
                  fab
                  icon
                  small
                  @click="addSelectedConnection()"
                  :disabled="loadings.send"
                  title="Adicionar conexão"
                )
                  v-icon(color="white") add
            v-row
              v-col(cols=12)
                span.subtitle-2 Conexões com usuários
              v-col(cols=12).mb-0.pb-0
                v-text-field(
                  v-model="connections.search.text"
                  label="Pesquisar conexão"
                  prepend-inner-icon="search"
                  rounded
                )
              v-col(cols=12 v-if="connections.search.result.length > 0").mb-5.mt-0.pt-0
                v-list(max-height="300px")
                  v-list-item(v-for="(conn, index) of connections.search.result" :key="`conn-${index}`")
                    v-list-item-avatar
                      v-icon person
                    v-list-item-content
                      v-row
                        v-col(cols=6) {{conn.user.name}}
                        v-col(cols=6) {{conn.type.name}}
                    v-list-item-action-text
                      v-btn(
                        icon
                        @click="removeConnection(conn)"
                      )
                        v-icon remove_circle
              v-col(cols=12 v-if="loadings.connections.connections")
                v-row(justify="center" align="center")
                  v-progress-circular(indeterminate color="primary")
              v-col(cols=12 v-if="connections.connections.length === 0 && !loadings.connections.connections")
                span.subtitle-3 Não há conexões com o usuário
            v-row(v-if="connections.added.length > 0")
              v-col(cols=12).pb-1
                span.subtitle-2 Novas conexões
              v-col(cols=12)
                perfect-scrollbar
                  v-list(max-height="300px")
                    v-list-item(v-for="(conn, index) of connections.added" :key="`newConn-${index}`")
                      v-list-item-avatar
                        v-icon person
                      v-list-item-content
                        v-row
                          v-col(cols=6) {{conn.user.name}}
                          v-col(cols=6) {{conn.type.name}}
                      v-list-item-action-text
                        v-btn(
                          icon
                          @click="removeAddedConnection(conn)"
                        )
                          v-icon remove_circle
    v-card-actions
      v-spacer
      v-btn(
        depressed
        color="primary"
        @click="save"
        :loading="loadings.save"
      )
        span {{ flags.editing ? 'Salvar' : 'Criar' }}
</template>

<script>
import { createModuleLogger } from '../../configs/winston'
import { mapState } from 'vuex'
import userApi from '../../api/user'
import responsibleApi from '../../api/responsible'
import usersConnectionsApi from '../../api/usersConnectionsApi'
import PhotoEditor from './PhotoEditor'
import _ from 'lodash'
import randomBytes from 'randombytes'

const l = createModuleLogger('UserManager')

const initialData = function () {
  return {
    lastLoginTime: null,
    createdTime: null,
    loadings: {
      save: false,
      openInNew: false,
      assocs: false,
      connections: {
        users: false,
        connections: false
      }
    },
    flags: {
      editing: false,
      snackbar: false,
      photoEditor: false
    },
    snackbar: {
      text: null
    },
    form: {
      name: null,
      type: null,
      profile: null,
      photo: null,
      matricula: null,
      cpf: null,
      email: null,
      registered: false,
      user: null,
      telefone: null,
      sync: false
    },
    rules: {
      required: (obj) => (obj && (typeof obj.length === typeof undefined || obj.length > 0)) || 'Este campo é obrigatório'
    },
    groupsAssocs: {
      assocs: [],
      removed: [],
      added: [],
      search: {
        text: null,
        result: []
      },
      selected: {
        role: null,
        groups: []
      },
      roles: [{
        name: 'Editor',
        value: 'publisher'
      }, {
        name: 'Visualizador',
        value: 'viewer'
      }, {
        name: 'Moderador',
        value: 'moderator'
      }]
    },
    connections: {
      connections: [],
      added: [],
      removed: [],
      selected: {
        users: [],
        type: null
      },
      search: {
        result: [],
        text: null,
        users: {
          text: null
        }
      },
      types: [{
        name: 'Responsável',
        value: 'parent'
      }, {
        name: 'Filho',
        value: 'child'
      }]
    },
    newProfileImage: null,
    newProfileImageFileName: null
  }
}

export default {
  props: ['visible', 'user'],
  components: {
    PhotoEditor
  },
  data: initialData,
  watch: {
    visible (visible) {
      if (visible) {
        l.info('Visible, set default')
        this.setDefault()
      } else {
        l.info('Not visible, clear')
        this.clear()
      }
    },
    'groupsAssocs.search.text': function (text) {
      this.searchAssoc(text)
    },
    'connections.search.text': function (text) {
      this.searchConnections(text)
    },
    'connections.search.users.text': function (text) {
      if (text && text.length > 0) {
        this.debouncedUsersSearch(text)
      } else {
        this.connections.search.users.result = this.connections.selected.users
      }
    }
  },
  computed: {
    ...mapState(['users', 'groups']),
    lastSeen () {
      const midnight = this.$moment().subtract(1, 'days').endOf('day')
      const lastLoginTime = this.$moment(this.lastLoginTime)

      if (lastLoginTime.isAfter(midnight)) {
        return `Último acesso hoje às ${lastLoginTime.format('HH:mm')}`
      } else {
        return `Último acesso em ${lastLoginTime.format('llll')}`
      }
    },
    createdAt () {
      const midnight = this.$moment().subtract(1, 'days').endOf('day')
      const createdTime = this.$moment(this.createdTime)

      if (createdTime.isAfter(midnight)) {
        return `Criado hoje às ${createdTime.format('HH:mm')}`
      } else {
        return `Criado em ${createdTime.format('llll')}`
      }
    }
  },
  methods: {
    saveNewProfileImage (url) {
      this.form.photo = url
    },
    openPhotoEditor () {
      this.flags.photoEditor = true

      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = 'image/*'

      fileInput.onchange = e => {
        const file = e.target.files[0]
        const reader = new FileReader()

        reader.onload = () => {
          this.newProfileImage = reader.result
          this.newProfileImageFileName = randomBytes(30).toString('hex') + '.' + file.name.split('.').slice(1).join('.')
        }

        if (file) {
          reader.readAsDataURL(file)
        } else {
          this.closePhotoEditor()
        }
      }

      fileInput.click()
    },
    closePhotoEditor () {
      this.flags.photoEditor = false
      this.newProfileImage = null
      this.newProfileImageFileName = null
    },
    removeConnection (conn) {
      l.info(`Removing connection with ${conn.user.name} and type ${conn.type.name}`)

      this.connections.removed.push(conn)

      this.connections.connections = this.connections.connections.filter(c => {
        return c.user.id !== conn.user.id || c.type.value !== conn.type.value
      })

      l.info('Reapplying search')
      this.searchConnections(this.connections.search.text)
    },
    searchConnections (text) {
      if (text && text.length > 0) {
        this.connections.search.result = this.connections.connections.filter(c => c.user.name.includes(text))
      } else {
        this.connections.search.result = this.connections.connections
      }
    },
    removeAddedConnection (conn) {
      l.info(`Removing added connection with ${conn.user.name} and type ${conn.type.name}`)

      this.connections.added = this.connections.added.filter(c => {
        return c.user.id !== conn.user.id || c.type.value !== conn.type.value
      })
    },
    addSelectedConnection () {
      l.info('Adding selected connection')

      const selected = this.connections.selected.users.map(u => {
        l.info(`Adding connection with user ${u.name}`)

        return {
          type: this.connections.selected.type,
          user: u
        }
      })

      this.connections.added.push(...selected)

      this.connections.selected.users = []
      this.connections.selected.type = null
    },
    async searchUsers (text) {
      this.loadings.connections.users = true

      const result = await userApi.list({
        next: 0,
        offset: 15,
        search: text
      })

      this.loadings.connections.users = false

      this.connections.search.users.result = [...result, ...this.connections.selected.users]
    },
    searchAssoc (text) {
      if (text && text.length > 0) {
        this.groupsAssocs.search.result = this.groupsAssocs.assocs.filter(a => a.group_cod.includes(text))
      } else {
        this.groupsAssocs.search.result = this.groupsAssocs.assocs
      }
    },
    addSelectedGroups () {
      l.info('Adding selected groups')

      const selected = this.groupsAssocs.selected.groups.map(g => {
        l.info(`Adding assoc with group ${g.cod}`)
        console.log('add', g)
        return {
          group_name: g.name,
          group_cod: g.cod,
          role: this.groupsAssocs.selected.role.value,
          role_name: this.groupsAssocs.selected.role.name
        }
      })

      this.groupsAssocs.added.push(...selected)

      this.groupsAssocs.selected.groups = []
      this.groupsAssocs.selected.role = null
    },
    removeAddedAssoc (assoc) {
      l.info(`Removing added assoc with ${assoc.group_cod} and role ${assoc.role}`)

      this.groupsAssocs.added = this.groupsAssocs.added.filter(a => {
        return a.group_cod !== assoc.group_cod || a.role !== assoc.role
      })
    },
    removeAssoc (assoc) {
      l.info(`Removing assoc with ${assoc.group_cod} and role ${assoc.role}`)

      this.groupsAssocs.removed.push(assoc)

      this.groupsAssocs.assocs = this.groupsAssocs.assocs.filter(a => {
        return a.group_cod !== assoc.group_cod || a.role !== assoc.role
      })

      l.info('Reapplying search')
      this.searchAssoc(this.groupsAssocs.search.text)
    },
    async logAsUser () {
      this.loadings.openInNew = true

      try {
        await this.$store.dispatch('auth/logAsUser', {
          userid: this.user.id
        })

        const institution = this.$store.getters['auth/institution']
        window.open(`http://${window.location.host}/${institution}/?temp_token=true`)
      } catch (err) {
        this.snackbar.text = 'Erro ao tentar logar como o usuário'
        this.flags.snackbar = true
      }

      this.loadings.openInNew = false
    },
    async setDefault () {
      l.info('Set default data')

      if (this.user) {
        l.info('User got, edit')

        this.flags.editing = true

        const userType = this.users.types.find(t => t.slug === this.user.type) || null
        const userProfile = this.users.profiles.find(p => p.id === this.user.user_profile_id) || null
        this.lastLoginTime = this.user.last_login_time
        this.createdTime = this.user.created_time

        Object.assign(this.form, {
          name: this.user.name,
          type: userType ? {
            name: userType.name,
            name_plural: userType.name_plural,
            slug: userType.slug
          } : null,
          profile: userProfile ? {
            id: userProfile.id,
            slug: userProfile.slug,
            name: userProfile.name
          } : null,
          photo: this.user.profile_image_url,
          matricula: this.user.matricula,
          cpf: this.user.cpf,
          email: this.user.email,
          telefone: this.user.telefones,
          user: this.user.user,
          registered: this.user.registered === 'true',
          sync: this.user.sync === 1
        })

        this.loadings.assocs = true
        this.loadings.connections.connections = true

        // Set associations with groups
        try {
          const result = await userApi.listGroupsAssocs(this.user.id)

          result.forEach(a => {
            const role = this.groupsAssocs.roles.find(r => r.value === a.role)
            a.role_name = role.name
          })

          this.groupsAssocs.assocs = result
          this.groupsAssocs.search.result = this.groupsAssocs.assocs
        } catch (err) {
          this.snackbar.text = err.message
          this.flags.snackbar = true
        }
        this.loadings.assocs = false

        // Set connections
        try {
          // TODO: Use general connections getter
          let result, connType

          if (this.user.type === 'parent') {
            connType = 'child'
            result = await responsibleApi.getChildren(this.user.cpf)
          } else if (this.user.type === 'aluno') {
            connType = 'parent'
            result = await responsibleApi.getParents(this.user.matricula)
          }

          if (result && connType) {
            this.connections.connections = result.map(c => {
              const type = this.connections.types.find(t => t.value === connType)

              return {
                type,
                user: c
              }
            })
            this.connections.search.result = this.connections.connections
          }
        } catch (err) {
          this.snackbar.text = err.message
          this.flags.snackbar = true
        }

        this.loadings.connections.connections = false
      }
    },
    clear () {
      l.info('Cleaning data')
      this.$refs.form.resetValidation()
      Object.assign(this.$data, initialData())
    },
    async save () {
      l.info('Saving user')

      this.loadings.save = true

      try {
        const form = this.$refs.form

        if (!form.validate()) {
          throw new Error('Preencha todos os campos corretamente')
        }

        const data = {}

        for (const key in this.form) {
          if (this.form[key]) {
            data[key] = this.form[key]
          }
        }

        data.id = this.flags.editing ? this.user.id : undefined
        data.type = this.form.type.slug
        data.user_profile_id = this.form.profile.id
        data.registered = this.form.registered ? 'true' : 'false'
        data.sync = this.form.sync ? 1 : 0
        data.profile_image_url = this.form.photo

        delete data.profile

        const savedUser = await this.$store.dispatch('users/save', data)

        if (this.groupsAssocs.removed.length > 0) {
          l.info('Destroy users assocs to destroy')
          await userApi.destroyGroupsAssocs(savedUser.id, this.groupsAssocs.removed)
        }

        if (this.groupsAssocs.added.length > 0) {
          l.info('Create users assocs to create')
          await userApi.createGroupsAssocs(savedUser.id, this.groupsAssocs.added)
        }

        if (this.connections.removed.length > 0) {
          l.info('Destroying users connections')
          await usersConnectionsApi.destroyUsersConnections(savedUser.id, this.connections.removed)
        }

        if (this.connections.added.length > 0) {
          l.info('Create users connections')
          await usersConnectionsApi.createUsersConnections(savedUser.id, this.connections.added)
        }

        l.info('User saved')
        this.snackbar.text = 'Usuário salvo com sucesso'
        this.flags.snackbar = true

        this.$emit('saved', savedUser)

        if (!this.user) {
          this.$emit('close')
        }
      } catch (err) {
        l.error(err.message)

        this.snackbar.text = err.message
        this.flags.snackbar = true
      }

      this.loadings.save = false
    }
  },
  created () {
    this.setDefault()

    this.debouncedUsersSearch = _.debounce(this.searchUsers, 400)
  }
}
</script>
