<template lang="pug">
  v-card
    v-stepper(v-model='step' vertical)
      v-stepper-items
        v-stepper-step(:complete='step > 1' step=1 v-if="!(hideSteps || hideDestinatarios)" editable) Destinatários
        v-stepper-content(step=1)
          v-container
            v-row(justify='start')
              v-checkbox.mr-2(v-model='selectedType' @change='updatePostsToType' value='parent' label='Responsáveis')
              v-checkbox.mr-2(v-model='selectedType' @change='updatePostsToType' value='aluno' label='Alunos')
            v-row(justify='start')
              v-col(cols="auto")
                v-flex(xs12)
                  v-autocomplete(v-model="groupsSelected.turmas" @input="updatePostsTo('turma', $event)" :items="groupsChildren.turmas" label="Adicionar uma turma" chips deletable-chips item-text="name" item-value="cod" multiple)
                    template(v-slot:selection="{ attr, on, item, selected, index }") {{ turmasText(index) }}
              v-col(cols="auto")
                v-flex(xs12)
                  v-autocomplete(v-model="groupsSelected.anos" @input="updatePostsTo('ano',$event)" :items="groupsChildren.anos" label="Adicionar um ano" item-text="name" chips deletable-chips item-value="cod" multiple style="max-width=100px;")
                    template(v-slot:selection="{ attr, on, item, selected, index }") {{ anosText(index) }}
              v-col(cols="auto")
                v-flex(xs12)
                  v-autocomplete(v-model="groupsSelected.ensinos" @input="updatePostsTo('ensino', $event)" :items="groupsChildren.ensinos" label="Adicionar um ensino" chips deletable-chips item-text="name" item-value="cod" multiple)
                    template(v-slot:selection="{ attr, on, item, selected, index }") {{ ensinosText(index) }}
              v-col(cols="auto")
                v-flex(xs12)
                  v-autocomplete(v-model="groupsSelected.filiais" @input="updatePostsTo('filial', $event)" :items="groupsChildren.filiais" label="Adicionar uma filial" chips deletable-chips item-text="name" item-value="cod" multiple)
                    template(v-slot:selection="{ attr, on, item, selected, index }") {{ filiaisText(index) }}
              v-col(cols="auto")
                v-flex(xs12)
                  v-autocomplete(v-model="usersSelected.alunos" @input="updatePostsTo('user', $event)" :items="usersChildren.alunos" label="Adicionar um aluno" chips item-text="name" item-value="id" multiple hide-selected)
                    template(v-slot:selection="{ attr, on, item, selected, index }") {{ alunosText(index) }}
              v-col(cols="auto")
                v-flex(xs12)
                  v-autocomplete(v-model="usersSelected.parents" @input="updatePostsTo('user', $event)" :items="usersChildren.parents" label="Adicionar um responsável" chips item-text="name" item-value="id" multiple hide-selected)
                    template(v-slot:selection="{ attr, on, item, selected, index }") {{ parentsText(index) }}
            v-row.align-center
              v-col(cols='auto')
                v-btn.primary(@click.stop='step++') Continuar
              v-col(cols='auto')
                //- v-if="recipientsCounted > 0"
                span.warning--text {{recipientsCountedText}}
        v-stepper-step(:complete='step > 2' step=2 v-if="!hideSteps" editable) Categoria
        v-stepper-content(step=2)
          v-container
            v-row(justify='start')
              v-col(cols='auto')
                v-select(v-model="model" @input="didChangeModel" :items="postModels" item-text="name" return-object clearable outlined label="Modelo da postagem")
              v-col(cols='auto')
                v-select(:items="postCategories" item-text="name" item-value="id" outlined label="Categoria da postagem" @input="saveCategory" return-object)
            v-row
              v-col(cols='auto')
                v-btn.primary(@click.stop='step++') Continuar
              v-col(cols='auto')
                v-btn(text @click.stop='step--') Voltar
        v-stepper-step(:complete='step > 3' step=3 v-if="!hideSteps") Conteúdo da postagem
        v-stepper-content(step=3)
          v-container(fluid)
            v-row
              v-col(cols='12' xs=12 )
                v-text-field(
                  v-model="post.title"
                  counter="45"
                  label="Título da postagem"
                  append-icon='mdi-format-title'
                  outlined
                )
              v-col(cols='12' xs=6 md=6)
                v-dialog(ref='dialogDate' v-model='dialogDatePicker' :return-value.sync='post.date' persistent max-width=400)
                  template(v-slot:activator='{ on }')
                    v-text-field(:value='parseDate(post.date)' label='Data da postagem' append-icon='event' readonly v-on='on' hide-details outlined)
                  v-date-picker(v-model='post.date' scrollable locale="pt-br" full-width :allowed-dates="allowedDates")
                    v-spacer
                    v-btn(text color='primary' @click='dialogDatePicker = false') Cancelar
                    v-btn(text color='primary' @click='$refs.dialogDate.save(post.date)') OK
              v-col(cols='12' xs=6 md=6)
                v-dialog(ref='dialogTime' v-model='dialogTimePicker' :return-value.sync='post.time' persistent max-width=400)
                  template(v-slot:activator="{ on }")
                    v-text-field(v-model='post.time' label='Horário da postagem' append-icon='access_time' readonly v-on='on' hide-details outlined)
                  v-time-picker(v-if='dialogTimePicker' v-model='post.time' format='24hr' full-width locale="pt-br")
                    v-spacer
                    v-btn(text color='primary' @click='dialogTimePicker = false') Cancelar
                    v-btn(text color='primary' @click="$refs.dialogTime.save(post.time)") OK
            v-row
              v-col(cols=12)
                quill-editor(v-model="post.body" ref="myQuillEditor" :options="editorOptions")
                  //- div#toolbar(slot='toolbar')
                  //-   select.ql-size
                  //-     option(value='small') Pequeno
                  //-     option(value='normal') Normal
                  //-     option(value='large') Grande
                  //-     option(value='huge') Gigante
                  //-   button.ql-bold
                  //-   button.ql-italic
            v-row
              v-col(cols=12)
                post-files-dropzone(
                  @uploaded="addFile"
                  @removed="removeFile"
                  @pending="loadingCreatePost = true"
                  @done="loadingCreatePost = false"
                )
              v-col(cols=12 md=6 v-show='false')
                v-row
                  v-col.mb-0.pb-0(cols=12)
                    v-text-field(v-model="post.link" label="Link do vídeo" clearable outlined)
                  v-col.mt-0.pt-0(cols=12)
                    v-btn.mt-0(block large color="info" outlined)
                      v-icon file_upload
                      | Enviar novo video
            v-dialog(v-model="dialogError" max-width="290")
              v-card
                v-card-text Por favor preencha o corpo ou o titulo da postagem
                v-card-actions
                  v-spacer
                  v-btn(color="primary" text @click="dialogError = false") OK
            v-dialog(v-model="dialogPostError" max-width="80%")
              v-card
                v-card-title Ocorreu um erro na criação da postagem
                v-card-text.warning--text Erro: {{ dialogPostErrorText }}
                v-card-text.warning--text {{ dialogPostErrorCode }}
                v-card-actions
                  v-spacer
                  v-btn(color="primary" text @click="dialogPostError = false") OK
            v-row
              v-col(cols='auto')
                v-btn(text @click.stop='step--')
                  | Voltar
              v-spacer
              v-col(cols="auto")
                v-btn(text @click="dialogPreview = true") Preview
              v-col(cols='auto')
                v-btn(color="primary" @click='dialogConfimation=true' :loading='loadingCreatePost')
                  v-icon(left) near_me
                  | {{post.id ? 'Salvar postagem' : 'Enviar postagem' }}
    v-dialog(v-model="dialogPreview" max-width="90%")
      v-container(fluid)
        Post(preview=true :post="postPreview")
    v-dialog(v-model="dialogConfimation" max-width="90%")
      v-container(fluid)
        v-card
          v-container
            v-row
              v-col
                span.ma-2.headline() Você tem ceteza que deseja enviar?
            v-row
              v-col
                span.ma-2() {{recipientsCountedText}}
            v-row
              v-col(cols="auto")
                v-btn(text @click='dialog=false') Cancelar
              v-spacer
              v-col(cols="auto")
                v-btn(color="primary" @click='createPost' :loading='loadingCreatePost')
                        v-icon(left) near_me
                        | {{post.id ? 'Salvar postagem' : 'Enviar postagem' }}
</template>
<script>
/**
 * This .vue needs language (portuguese/english refactor)
 */
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import Firebase from '../../store/firebase'
import VueUploadMultipleImage from 'vue-upload-multiple-image'
import { quillEditor } from 'vue-quill-editor'
import PostApi from '../../api/posts.js'
import { mapFields } from 'vuex-map-fields'
import Post from '../feed/PostViewer'
// import videoUploader from '../../api/videoUploader'
import PostFilesDropzone from './PostFilesDropzone'

export default {
  name: 'post-editor',
  components: {
    VueUploadMultipleImage,
    quillEditor,
    Post,
    PostFilesDropzone
  },
  props: {
    step: {
      type: Number,
      default: () => 1
    },
    hideSteps: {
      type: Boolean,
      default: () => false
    },
    hideDestinatarios: {
      type: Boolean,
      default: () => false
    },
    post: {
      type: Object,
      default: () => {
        return {
          title: '',
          body: '',
          time: this.$moment().format('hh:mm'),
          date: this.$moment().format('YYYY-MM-DD'),
          mediaURL: [],
          medias: [],
          images: [],
          category_id: '',
          to: []
        }
      }
    }
  },
  data () {
    return this.initialState()
  },
  watch: {
    'post.date' () {
      this.syncPublicationTime()
    },
    'post.time' () {
      this.syncPublicationTime()
    }
  },
  computed: {
    ...mapFields('groups', ['groupsChildren']),
    ...mapFields('users', ['users', 'usersChildren']),
    recipientsCountedText () {
      if (this.recipientsCounted === 1) {
        return `${this.recipientsCounted} Pessoa receberá essa postagem`
      } else {
        return `${this.recipientsCounted} Pessoas receberão essa postagem`
      }
    },
    postPreview () {
      var preview = this.post
      preview.category_name = this.previewOption.selectedCategory.name
      preview.category_color = this.previewOption.selectedCategory.color

      if (this.post.medias && this.post.medias.length > 0) {
        preview.image_url = this.post.medias[0].url
        preview.image_height = this.previewOption.imageHeigth
      }
      return preview
    }
  },
  methods: {
    initialState () {
      return {
        mediasMap: {},
        selectedType: [],
        dialogDatePicker: false,
        dialogTimePicker: false,
        dialogError: false,
        dialogPostError: false,
        dialogPostErrorText: '',
        dialogPostErrorCode: '',
        dialogPostErrorJson: {},
        postCategories: [],
        postModels: [],
        usersSelected: {
          parents: [],
          alunos: []
        },
        model: null,
        previewOption: {
          selectedCategory: {},
          imageHeigth: null
        },
        usuarios: [],
        groups: {
          filiais: [],
          ensinos: [],
          anos: [],
          turmas: []
        },
        groupsSelected: {
          filiais: [],
          ensinos: [],
          anos: [],
          turmas: [],
          usuarios: []
        },
        progressUpload: '',
        dialogConfimation: false,
        dialogPreview: false,
        images: [],
        loadingCreatePost: false,
        recipientsCounted: 0,
        editorOptions: {
          modules: {
            // toolbar: '#toolbar'
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote', 'code-block'],
              [{ 'header': 1 }, { 'header': 2 }],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              [{ 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'direction': 'rtl' }],
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              [{ 'font': [] }],
              [{ 'color': [] }, { 'background': [] }],
              [{ 'align': [] }],
              ['clean'],
              ['link']
            ]
          }
        },
        videoUploaderPromises: new Map()
      }
    },
    saveCategory (item) {
      this.post.category_id = item.id
      this.previewOption.selectedCategory = item
    },
    async getCount () {
      var cods = []
      for (let filial of this.groupsSelected.filiais) {
        cods.push(filial)
      }
      for (let ensino of this.groupsSelected.ensinos) {
        cods.push(ensino)
      }
      for (let ano of this.groupsSelected.anos) {
        cods.push(ano)
      }
      for (let turma of this.groupsSelected.turmas) {
        cods.push(turma)
      }
      var studentIds = []
      for (let student of this.usersSelected.alunos) {
        studentIds.push(student)
      }
      var parentIds = []
      for (let parent of this.usersSelected.parents) {
        parentIds.push(parent)
      }
      var query = {
        cods: cods,
        alunos: (this.selectedType.filter(c => c === 'aluno').length > 0),
        responsaveis: (this.selectedType.filter(c => c === 'parent').length > 0),
        student_ids: studentIds,
        parent_ids: parentIds
      }
      try {
        var res = await PostApi.countRecipients(query)
        this.recipientsCounted = res.data.count
      } catch (err) {
        console.error(err)
      }
    },
    filiaisText (index) {
      if (index === 0) {
        return this.groupsSelected.filiais.length + ((this.groupsSelected.filiais.length === 1) ? ' selecionado' : ' selecionados')
      } else {
        return ''
      }
    },
    ensinosText (index) {
      if (index === 0) {
        return this.groupsSelected.ensinos.length + ((this.groupsSelected.ensinos.length === 1) ? ' selecionado' : ' selecionados')
      } else {
        return ''
      }
    },
    anosText (index) {
      if (index === 0) {
        return this.groupsSelected.anos.length + ((this.groupsSelected.anos.length === 1) ? ' selecionado' : ' selecionados')
      } else {
        return ''
      }
    },
    turmasText (index) {
      if (index === 0) {
        return this.groupsSelected.turmas.length + ((this.groupsSelected.turmas.length === 1) ? ' selecionado' : ' selecionados')
      } else {
        return ''
      }
    },
    alunosText (index) {
      if (index === 0) {
        return this.usersSelected.alunos.length + ((this.usersSelected.alunos.length === 1) ? ' selecionado' : ' selecionados')
      } else {
        return ''
      }
    },
    parentsText (index) {
      if (index === 0) {
        return this.usersSelected.parents.length + ((this.usersSelected.parents.length === 1) ? ' selecionado' : ' selecionados')
      } else {
        return ''
      }
    },
    getImageDimensions (file) {
      return new Promise(function (resolve, reject) {
        var i = new Image()
        i.onload = function () {
          resolve(i.height)
        }
        i.src = file
      })
    },
    addFile (data) {
      const media = {
        uuid: data.uuid,
        type: data.type,
        url: data.url
      }
      this.post.medias.push(media)
    },
    removeFile (uuid) {
      this.post.medias = this.post.medias.filter(m => m.uuid !== uuid)
    },
    parseDate (d) {
      return this.$moment(d).format('DD/MM/YYYY')
    },
    syncPublicationTime () {
      let dateTime = this.$moment(`${this.post.date} ${this.post.time}`)
      this.post.publication_time = this.$moment(dateTime).format('YYYY-MM-DD HH:mm:ss.SSSSZ')
    },
    async uploadImageSuccess (formData, index, fileList) {
      let imageFile = fileList[index]
      const self = this
      this.previewOption.imageHeigth = await this.getImageDimensions(imageFile.path)
      const downloadURL = await Firebase.uploadFile(imageFile, self)

      imageFile.indexOnSelector = index
      imageFile.path = downloadURL

      this.post.image_url = downloadURL

      if (!this.post.medias || !this.post.medias.length) {
        this.post.medias = []
      }

      if (!this.post.images || !this.post.images.length) {
        this.post.images = []
      }

      this.post.medias.push({
        url: downloadURL,
        type: 'image'
      })
      this.post.images.push(imageFile)

      // this.post.mediaURL.push(imageFile)
    },
    beforeRemove (index, done, fileList) {
      this.post.images = this.post.images.filter((image) => {
        return image.indexOnSelector !== index
      })
      done()
      // this.events.images = fileList;
    },
    editImage (formData, index, fileList) {
      // consousersSelectedle.log('editImage', formData, index, fileList)
    },
    dataChange (data) {
    },
    allowedDates (val) {
      return parseInt(val.split('-')[2], 10) >= this.$moment().date()
    },
    updatePostsTo (groupType, groups) {
      if (groupType === 'user') {
        let allSelectedUsers = [
          ...this.usersSelected.alunos,
          ...this.usersSelected.parents
        ]

        this.post.to = [
          ...this.post.to.filter(group => group.type === 'group'),
          ...allSelectedUsers.map(user => ({
            type: 'user',
            user_id: user
          }))
        ]
      } else {
        let allSelectedGroups = [
          ...this.groupsSelected.filiais,
          ...this.groupsSelected.ensinos,
          ...this.groupsSelected.anos,
          ...this.groupsSelected.turmas,
          ...this.groupsSelected.usuarios
        ]

        this.post.to = [
          ...this.post.to.filter(group => group.type === 'user'),
          ...allSelectedGroups.map(group => ({
            type: 'group',
            group_cod: group,
            bonds: this.selectedType
          }))
        ]
      }
      this.getCount()
    },
    updatePostsToType () {
      this.getCount()
      this.post.to = this.post.to.map(group => {
        group.groupTypeAssoc = this.selectedType
        return group
      })
    },
    async createPost () {
      if (this.post.title === '' && this.post.body === '') {
        this.dialogConfimation = false
        this.dialogError = true
        return
      }
      this.loadingCreatePost = true

      // Provisionally syncing datetime only on creating/editing
      this.syncPublicationTime()

      try {
        if (this.post.id) {
          await this.$store.dispatch('posts/edit', this.post)
        } else {
          await this.$store.dispatch('posts/create', this.post)
          this.$emit('postCreated')
        }

        this.dialogConfimation = false
        this.loadingCreatePost = false

        Object.assign(this.$data, this.initialState())
      } catch (err) {
        console.error(err)

        this.dialogPostErrorText = err.response.data
        this.dialogPostErrorCode = err.response.status
        this.dialogConfimation = false
        this.dialogPostError = true
        this.loadingCreatePost = false
      }
    },
    didChangeModel () {
      this.post.title = this.model.title
      this.post.body = this.model.body
      this.post.category_id = this.model.category_id
      for (let media of this.model.Media) {
        this.post.mediaURL.push(media.mediaUrl)
        this.images.push(media.mediaUrl)
      }
    }
  },
  async created () {
    // this.$store.dispatch('users/list')

    if (this.post.created_time) {
      this.post.date = this.$moment(this.post.created_time).format('YYYY-MM-DD')
      this.post.time = this.$moment(this.post.created_time).format('HH:mm')
    }
    var resCategories = await PostApi.getCategories(this.$route.params.institution)
    var resModels = await PostApi.getModels(this.$route.params.institution)

    this.postCategories = resCategories.data
    this.postModels = resModels.data
  }
}
</script>
<style lang="stylus">
  /* Personalizando o  vue-quill-editor */
  .ql-editor {
    min-height: 200px
  }

  /* Personalizando o  vue-multiple-upload-image */
  label.image-edit {
    display: none !important;
  }
  .image-container, .preview-image, .image-overlay, .image-list-item, .image-primary {
    border-radius: 0px !important;
  }
  .image-container {
    height: 130px !important;
    width: 100% !important;
    margin: 10px 10px 10px 0px !important
  }
</style>
