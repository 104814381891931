const winston = require('winston')
const { combine, timestamp, label, colorize, printf } = winston.format

const ignoreLogInCLI = winston.format((info) => info.private ? false : info)
const quotiCLIFormat = printf(({ level, message, timestamp }) => `${timestamp} ${level}: ${message}`)

/**
 * @description Winston logger for a module
 * @param {String} moduleName Module name
 */
export const createModuleLogger = function (moduleName) {
  return winston.createLogger({
    format: combine(
      timestamp()
    ),
    transports: [
      new winston.transports.Console({
        format: combine(
          ignoreLogInCLI(),
          colorize(),
          label({ label: moduleName, message: true }),
          quotiCLIFormat
        )
      })
    ]
  })
}
