<template lang="pug">
  v-card(min-height="300")
    span.subtitle-1.font-weight-medium.ml-4 Informações dos Participantes
    div(v-if="!userInfos")
      v-row.justify-center(no-gutters)
        v-progress-circular(size="70" indeterminate color="primary")
    v-row(no-gutters v-else)
      v-col(cols="auto" v-for="info in userInfos" :key="info.id")
        v-card.ma-2(outlined max-width="300")
          v-list-item(three-line)
            v-list-item-avatar(size="80" color="grey")
                  span(class="white--text headline" v-if="!info.image") {{ getNameInitials(info.name) }}
                  img(:src="info.image" v-if="info.image")
            v-list-item-content
              v-list-item-title {{info.name}}
              v-list-item-subtitle CPF: {{cpfFormated(info.cpf)}}
              v-list-item-subtitle grupos: {{cpfFormated(info.grupos)}}
</template>
<script>
import ouvidoria from '../../api/chat'
export default {
  props: {
    chat: {
      type: Object
    }
  },
  data () {
    return {
      userInfos: null
    }
  },
  async created () {
    const response = await ouvidoria.getParticipants(this.chat.id)
    this.userInfos = response.participants
  },
  methods: {
    cpfFormated (cpf) {
      return cpf || 'não informado'
    },
    gruposFormated (grupos) {
      return grupos || 'não informado'
    },
    getNameInitials (name) {
      const splitName = name.split(' ')
      const firstName = splitName[0]
      const lastName = splitName[splitName.length - 1]

      return firstName[0] + lastName[0]
    }
  }
}
</script>
