<template lang="pug">
  v-container.pa-4
    v-row(v-if="loadings.newConversation === true")
      v-col
        v-row(justify="center")
          span Carregando conversa
        v-row.mt-2(justify="center")
          v-progress-circular(indeterminate color="primary")
    v-row(v-if="loadings.newConversation === false")
      v-col
        v-text-field(
          text
          v-model="recipients.search"
          filled color="black"
          rounded
          flat
          hide-details
          append-icon='search'
          label='Pesquisar destinatário'
          hint='Selecione um destinatário para inciar uma conversa'
          persistent-hint
          @keyup.enter="search"
        )
    v-row(v-if="loadings.newConversation === false")
      v-col(v-if="loadings.recipients === false && recipients && recipients.list")
        perfect-scrollbar
          v-list(max-height="300px")
            template(v-for="(recipient,index) in recipients.list")
              v-divider(v-if="index > 0")
              v-list-item(two-line :key="recipient.id" @click="newChat(recipient)")
                v-list-item-avatar(v-if="recipient.image_url && recipient.image_url.length > 0")
                  v-img(:src="recipient.image_url" width="25px")
                v-list-item-avatar(v-else)
                  v-icon(size=40) account_circle
                v-list-item-content()
                  v-list-item-title() {{recipient.name}}
                  v-list-item-subtitle() {{ getUserTypeName(recipient.type) }}
      v-col(v-if="loadings.recipients === true")
        v-row(justify="center")
          span Listando usuários
        v-row(justify="center")
          v-progress-circular(indeterminate color="primary")
</template>
<script>
import { mapState } from 'vuex'
import ChatApi from '../../api/chat'

export default {
  data () {
    return {
      loadings: {
        recipients: false,
        newConversation: false
      },
      last: 0,
      recipients: {
        list: [],
        next: 0,
        offset: 30,
        search: ''
      }
    }
  },
  computed: {
    ...mapState(['users'])
  },
  methods: {
    getUserTypeName (slug) {
      const userType = this.users.types.find(type => type.slug === slug)
      if (userType) {
        return userType.name
      } else {
        return null
      }
    },
    async newChat (entity) {
      this.loadings.newConversation = true
      console.log('Creating chat with', entity)

      const Room = await this.$store.dispatch('chat/createRoom', [entity])
      const Entity = { name: entity.name }

      this.loadings.newConversation = false
      this.$emit('newchat', { Entity, Room })
    },
    search () {
      this.recipients.list = []
      this.recipients.next = 0
      this.loadMore()
    },
    async loadMore () {
      this.loadings.recipients = true

      const listCanTalk = await ChatApi.listCanTalk({
        search: this.recipients.search,
        offset: this.recipients.last++
      })
      this.recipients.list = listCanTalk
      // const list = await this.$store.dispatch('users/search', {
      //   types: this.users.types.map(type => type.slug),
      //   next: this.recipients.next,
      //   offset: this.recipients.offset,
      //   search: this.recipients.search
      // })

      // const last = list[list.length - 1].id
      // this.recipients.list.push(...list)
      // this.recipients.next = last + 1

      this.loadings.recipients = false
    }
  },
  mounted () {
    // TEMPORARY: loadMore on scroll limit reached
    this.loadMore()
  }
}
</script>
