<template lang="pug">
  v-card
    v-stepper(v-model='step' vertical)
      v-stepper-items
        v-stepper-step(:complete='step > 1' step=1 editable) Turmas
        v-stepper-content(step=1)
          v-container
            v-row(justify='center')
              v-col(xs=12 md=6)
                v-combobox(v-model="selectedTurma" :items="uploadGroups" label="Selecione uma turma" item-text="name" item-value="cod" :loading="loadingTurmas" @input="getStudents")
            v-row
              v-col(cols='auto')
                v-btn.primary(@click.stop='step++') Continuar
        v-stepper-step(:complete='step > 2' step=2 editable) Selecionar Foto
        v-stepper-content(step=2)
          v-container
            v-row
              v-col
                v-text-field(
                  v-model="post.title"
                  counter="45"
                  label="Título"
                  prepend-icon='mdi-format-title'
                  outlined
                )
                v-text-field(
                  v-model="post.body"
                  counter="100"
                  label="Descrição"
                  prepend-icon='mdi-format-title'
                  outlined
                )
                vue-upload-multiple-image(:maxImage="20" :data-images="images"
                  @upload-success="uploadImageSuccess" @before-remove="beforeRemove" @edit-image="editImage"
                  @data-change="dataChange" dragText="Arraste e solte as imagens" browseText="Carregar imagens"
                  primaryText="Principal" markIsPrimaryText="Marcar como principal"
                  popupText="Esta imagem será exibida como padrão" dropText="Solte seu arquivo aqui ...    ")
          v-row
            v-col(cols='auto')
                v-btn.primary(@click.stop='step++') Continuar
            v-col(cols='auto')
              v-btn(text @click.stop='step--')
                | Voltar
        v-stepper-step(:complete='step > 3' step=3 editable) Alunos
        v-stepper-content(step=3)
          v-container
            v-row(justify='center')
              v-col(xs=12 md=6)
                v-combobox(v-model="selectedStudents" :items="uploadStudents" label="Selecione os Alunos" item-text="name" item-value="id" :loading="loadingStudents" multiple)
            v-row
              v-col(cols='auto')
              v-btn(text @click.stop='step--')
                | Voltar
              v-spacer
              v-col(cols='auto')
              v-btn(color="primary" @click="createPhotoPost" :loading="Uploading")
                v-icon(left) near_me
                | Enviar postagem
    v-snackbar(v-model="showSnackbar") Post Criado com sucesso
      v-btn(color="pink" text @click="showSnackbar = false") Fechar
</template>
<script>

import VueUploadMultipleImage from 'vue-upload-multiple-image'
import Firebase from '../../store/firebase.js'
// import Post from '../../api/posts.js'
import groups from '../../api/organizationgroups'

export default {
  name: 'postcreator',
  components: {
    VueUploadMultipleImage
  },
  data () {
    return {
      step: 1,
      dialogDatePicker: false,
      dialogTimePicker: false,
      uploadStudents: [],
      uploadGroups: [],
      progressUpload: '',
      images: [],
      selectedTurma: '',
      selectedStudents: [],
      loadingTurmas: false,
      loadingStudents: false,
      showSnackbar: false,
      Uploading: false,
      title: '',
      body: '',
      settings: [],
      post: {
        title: '',
        body: '',
        category_id: 6,
        medias: [],
        to: []
      }
    }
  },
  async created () {
    this.loadingTurmas = true
    var institutionSlug = this.$route.params.institution
    var res = await groups.listWithType(institutionSlug, 'turma')
    this.loadingTurmas = false
    this.uploadGroups = res
  },
  methods: {
    async getStudents () {
      this.loadingStudents = true
      var res = await groups.listAssocs({ group_cod: this.selectedTurma.cod })
      this.uploadStudents = res
      this.loadingStudents = false
    },
    async uploadImageSuccess (formData, index, fileList) {
      let imageFile = fileList[index]
      const self = this

      const downloadURL = await Firebase.uploadImage(imageFile, self)
      imageFile.indexOnSelector = index
      imageFile.path = downloadURL
      this.images.push(imageFile)
      this.post.medias.push({
        url: downloadURL,
        type: 'png'
      })
    },
    resetPost () {
      this.showSnackbar = true
      this.images = []
      this.post.medias = []
      this.post.to = []
      this.selectedStudents = []
      this.step = 1
    },
    async createPhotoPost () {
      this.Uploading = true
      for (let student of this.selectedStudents) {
        this.post.to.push({
          type: 'matricula',
          user_id: parseInt(student.matricula)
        })
      }
      // var res = await Post.createPost(this.post)
      this.Uploading = false
      this.resetPost()
    },
    beforeRemove (index, done, fileList) {
      this.images = this.images.filter((image) => {
        return image.indexOnSelector !== index
      })
      done()
      // this.events.images = fileList;
    },
    editImage (formData, index, fileList) {
    },
    dataChange (data) {
    }
  }
}
</script>
<style lang="stylus">
  /* Personalizando o  vue-quill-editor */
  .ql-editor {
    min-height: 200px
  }

  /* Personalizando o  vue-multiple-upload-image */
  label.image-edit {
    display: none !important;
  }
  .image-container, .preview-image, .image-overlay, .image-list-item, .image-primary {
    border-radius: 0px !important;
  }
  .image-container {
    width: 100% !important;
    margin: 10px 10px 10px 0px !important
  }
</style>
